import React from "react";
import Moment from "react-moment";
import { DetailResult } from "../../models/exekuceModel";
import ExekuceDetailInnerItem from "../../components/order/ExekuceDetailInnerItem";
import { Link, useParams } from "react-router-dom";
import ModalQuestion from "../../components/_base/modalQuestion";

interface Props {
  exekuceDetail: DetailResult;
  index: number;
  total: number;
}

export default function ExekuceDetailItems({
  exekuceDetail,
  index,
  total,
}: Props) {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <div className="exekuce-CardList-item">
        <span className="exekuce-CardList-count">
          {index} / {total}
        </span>
        <article className="exekuce-Card exekuce-Card--paid">
          <header className="exekuce-Card-header">
            <h3 className="exekuce-Card-title">
              Spisová značka:{" "}
              <strong className="color-red">{exekuceDetail.ex_cislo}</strong>
            </h3>
            <div className="exekuce-Card-controls exekuce-Card-controls--paid">
              {exekuceDetail.detail_error_status === 0 && (
                <Link
                  to={`/Exekuce/GetExekuceDetailPdf?id=${id}&causeId=${exekuceDetail.cause_id}`}
                  className="FileLink mr-20"
                  target="_blank"
                >
                  Uložit výpis jako PDF
                </Link>
              )}
              {exekuceDetail.detail_error_status !== 0 && (
                <strong className="color-red pr-15">
                  Detail exekuce se nepodařilo načíst!
                </strong>
              )}
              <span className="exekuce-Card-textWithCheck">
                <strong>Prověřeno</strong>
              </span>
            </div>
          </header>
          <main className="exekuce-Card-content">
            {exekuceDetail.detail_error_status !== 0 && (
              <div className="exekuce-InfoCount pb-30">
                <div className="exekuce-InfoCount-note">
                  <span className="VisualIcon VisualIcon--circleWarning smaller"></span>
                  <div className="Flex-column">
                    <span>
                      <strong className="color-red">Nepodařilo se</strong>{" "}
                      načíst detail exekuce, zkuste obnovit stránku.
                    </span>
                    <span>
                      <ModalQuestion
                        link="Více informací"
                        header="Co dělat, když se nepodařilo načíst detail exekuce?"
                        text="V případě, že nebyl detail exekuce načten, zkoušejte průběžně obnovovat webovou stránku. Data jsou zřejmě ještě na cestě, nebo se vyskytl dočasný výpadek systému. Výpadky většinou netrvají déle než 60 minut."
                        text2="Pokud máte jakýkoliv dotaz, rádi vám na něj odpovíme na adrese <a href='mailto:exekuce@cebia.cz'>exekuce@cebia.cz.</a>"
                      />
                    </span>
                  </div>
                </div>
                <div className="Grid-cell smw:w-auto">
                  <button className="Button" onClick={() => history.go(0)}>
                    <span className="Button-content">Obnovit stránku</span>
                  </button>
                </div>
              </div>
            )}

            <table className="TableDataPair TableDataPair--thWide TableDataPair--mobileWrap mb-40">
              <tbody>
                {exekuceDetail.datetime_zalozeni !== null && (
                  <tr>
                    <th>Datum založení:</th>
                    <td>
                      <strong>
                        <Moment format="DD. MM. YYYY">
                          {exekuceDetail.datetime_zalozeni ?? ""}
                        </Moment>
                      </strong>
                    </td>
                  </tr>
                )}
                {exekuceDetail.datetime_vlozeni !== null && (
                  <tr>
                    <th>Datum vložení:</th>
                    <td>
                      <strong>
                        <Moment format="DD. MM. YYYY">
                          {exekuceDetail.datetime_vlozeni ?? ""}
                        </Moment>
                      </strong>
                    </td>
                  </tr>
                )}
                {exekuceDetail.datetime_zapisu !== null && (
                  <tr>
                    <th>Datum zápisu:</th>
                    <td>
                      <strong>
                        <Moment format="DD. MM. YYYY">
                          {exekuceDetail.datetime_zapisu ?? ""}
                        </Moment>
                      </strong>
                    </td>
                  </tr>
                )}
                {exekuceDetail.jmeno_kancelare !== null &&
                  exekuceDetail.jmeno_kancelare.length > 0 && (
                    <tr>
                      <th>Název exekutorského úřadu:</th>
                      <td>
                        <strong>{exekuceDetail.jmeno_kancelare}</strong>
                      </td>
                    </tr>
                  )}
                {exekuceDetail.one_time_price !== null &&
                  exekuceDetail.one_time_price.length > 0 &&
                  exekuceDetail.one_time_price !== "0.00" && (
                    <tr>
                      <th>Jednorázová peněžitá povinnost:</th>
                      <td>
                        <strong>{exekuceDetail.one_time_price} Kč</strong>
                      </td>
                    </tr>
                  )}
                {exekuceDetail.regular_price !== null &&
                  exekuceDetail.regular_price.length > 0 &&
                  exekuceDetail.regular_price !== "0.00" && (
                    <tr>
                      <th>Pravidelně splácená peněžitá povinnost:</th>
                      <td>
                        <strong>{exekuceDetail.regular_price} Kč</strong>
                      </td>
                    </tr>
                  )}
                {exekuceDetail.monetary_description !== null &&
                  exekuceDetail.monetary_description.length > 0 && (
                    <tr>
                      <th>Popis peněžité povinnosti:</th>
                      <td>
                        <strong>{exekuceDetail.monetary_description}</strong>
                      </td>
                    </tr>
                  )}
                {exekuceDetail.nonmonetary_description !== null &&
                  exekuceDetail.nonmonetary_description.length > 0 && (
                    <tr>
                      <th>Popis nepeněžité povinnosti:</th>
                      <td>
                        <strong>{exekuceDetail.nonmonetary_description}</strong>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <div className="Separator mb-20"></div>

            {exekuceDetail.documents?.map((itemD, indexD) => {
              return (
                <ExekuceDetailInnerItem
                  key={"exekDItem" + indexD}
                  document={itemD}
                  subjects={exekuceDetail.subjects}
                />
              );
            })}
          </main>
        </article>
      </div>
    </>
  );
}
