import React, { Component } from "react";
import { ExekuceTicketPrice } from "../../models/orderModel";

export class Price extends Component {
  render() {
    return (
      <section className="Section Section--appended">
        <div className="Container">
          <h2 className="Section-title Section-title--center Beta">
            Cena kontroly
          </h2>
          <div className="homepage-PriceTable">
            <table className="homepage-PriceTable-table">
              <tbody>
                <tr>
                  <th>
                    <span className="GamaSize">Kontrola exekučních řízení</span>
                    Přehled vedených exekucí
                  </th>
                  <td className="color-blue">
                    <strong className="text-noWrap">
                      {ExekuceTicketPrice} Kč
                    </strong>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="GamaSize">
                      Zobrazení detailu jedné exekuce
                    </span>
                  </th>
                  <td className="color-blue">
                    <strong className="text-noWrap">
                      {ExekuceTicketPrice} Kč
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

export default Price;
