export default class ContactValues {
    id?: string = undefined;
    type:string = "";
    firstName:string = "";
    lastName:string = "";
    phone:string = "";
    email: string = "";
    message:string = "";
    cGdpr: Boolean = false;
}
