

export function RcValidator(value: string | undefined) {
    let isValid = true;
    if (value && value?.length > 0) {
        let tmpValue = value.replace(/ /g, '').replace('/', '').replace('\\', '');
        let reg = new RegExp('^\\d+$');
        isValid = tmpValue.length >= 9 && tmpValue.length < 11 && reg.test(tmpValue);

        if (isValid) {
            let is1953 = tmpValue.length === 9;

            let year = parseInt(tmpValue.substring(0, 2));
            let month = parseInt(tmpValue.substring(2, 4));
            let day = parseInt(tmpValue.substring(4, 6));

            if (is1953 && year > 53)
                isValid = false;

            if (isValid && !is1953 && (parseInt(tmpValue.substring(0, 9)) % 11 % 10) !== parseInt(tmpValue.substring(9, 10))) {
                isValid = false;
            }

            if (isValid) {

                year += is1953 && year < 54 || !is1953 && year > new Date().getFullYear() % 100 ? 1900 : 2000;

                if (month > 70 && month < 82 && year > 2003)
                    month -= 70;
                else if (month > 50 && month < 63) month -= 50;
                else if (month > 20 && month < 33 && year > 2003) month -= 20;

                if (day > 50) day -= 50;

                if (month < 1 || month > 12 || year < 0 || year > 2100 || day <= 0 || day > new Date(year, month, 0).getDate())
                    isValid = false;
            }
        }
    }

    return isValid;
}

export function IcValidator(value: string | undefined) {
    let isValid = true;
    if (value && value?.length > 0) {
        let tmpValue = value.replace(/ /g, '').replace('/', '');
        let reg = new RegExp('^\\d+$');

        if (tmpValue.length > 3 && tmpValue.length < 9 && reg.test(tmpValue)) {
            let nCrc = 0;
            for (let i = tmpValue.length - 2; i >= 0; i--) {
                nCrc += parseInt(tmpValue[i]) * (tmpValue.length - i);
            }
            nCrc = 11 - (nCrc % 11);

            if (nCrc === 10)
                nCrc = 0;
            if (nCrc === 11)
                nCrc = 1;

            if (parseInt(tmpValue[tmpValue.length - 1]) != nCrc)
                isValid = false;
        }
        else {
            isValid = false;
        }
    }

    return isValid;
}