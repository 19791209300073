import React, { useEffect, useState } from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import Moment from 'react-moment'
import { updateCell } from '../../BL/adminBL'


declare module 'react-table' {
    // take this file as-is, or comment out the sections that don't apply to your plugin configuration

    interface TableOptions<D extends object>
        extends UseExpandedOptions<D>,
        UseFiltersOptions<D>,
        UseGlobalFiltersOptions<D>,
        Record<string, any> { }

    interface TableInstance<D extends object = {}>
        extends UseColumnOrderInstanceProps<D>,
        UseExpandedInstanceProps<D>,
        UseFiltersInstanceProps<D>,
        UseGlobalFiltersInstanceProps<D>,
        UseRowStateInstanceProps<D>,
        UseSortByInstanceProps<D> {
        editable: boolean;
    }

    interface TableState<D extends object = {}>
        extends UseColumnOrderState<D>,
        UseExpandedState<D>,
        UseFiltersState<D>,
        UseGlobalFiltersState<D>,
        UseRowSelectState<D>,
        UseRowStateState<D>,
        UseSortByState<D> { }

    interface ColumnInterface<D extends object = {}>
        extends UseFiltersColumnOptions<D>,
        UseGlobalFiltersColumnOptions<D>,
        UseSortByColumnOptions<D> { }

    interface ColumnInstance<D extends object = {}>
        extends UseFiltersColumnProps<D>,
        UseSortByColumnProps<D> { }

    interface Cell<D extends object = {}> extends UseGroupByCellProps<D>, UseRowStateCellProps<D> { }

    interface Row<D extends object = {}>
        extends UseExpandedRowProps<D>,
        UseRowSelectRowProps<D>,
        UseRowStateRowProps<D> { }
}



const EditableCell = ({ value: initialValue, row: { index, values }, column: { id } }: any) => {
    const [value, setValue] = useState(initialValue ?? "");

    useEffect(() => { setValue(initialValue); }, [initialValue])

    const updateData = () => {
        updateCell(values.viewId, id, value);
    }

    return <input className="Input" value={value ?? ""} onChange={e => setValue(e.target.value)} onBlur={updateData} />;
}

const routeChange = ({ url }: any) => {  
    window.location.href = url``
}

const SendButton = ({ row }: any) => {
    const [dsbl, setDsbl] = useState(false);

    return <button className="Button Button--small" disabled={dsbl} onClick={() => { setDsbl(true); updateCell(row.original.viewId, "sendEmail", "1"); }}><span className="Button-content">Odeslat</span></button>
}

const DokladButton = ({ row }: any) => {
    const [dsbl, setDsbl] = useState(false);

    return <a target="_blank" href={row.original.dokladUrl}>Doklad</a>
}

export const GlobalHeadFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Hledat: {' '}
            <input className="Input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    // onChange(e.target.value);
                    setGlobalFilter(e.target.value);
                }}
                placeholder={`hledat...`}
                style={{
                    height: '40px'
                }}
            />
        </span>
    )
}



export const headDefCol =
    () => [
        {
            Header: ' ',
            columns: [
                // {
                //     Header: "",
                //     accessor: "viewId"
                // },
                {
                    Header: 'Var. symb.',
                    accessor: 'vs',
                    width: 60,
                },
                {
                    Header: 'Vytvořeno',
                    accessor: 'created',
                    Cell: ({ row }: any) => {
                        return <Moment format='DD.MM.YYYY HH:mm'>{row.original.created ?? ""}</Moment>
                    }
                },
                {
                    Header: 'Typ',
                    accessor: 'initType',
                },
                {
                    Header: 'Počet exek.',
                    accessor: 'executionsCount',
                },
                {
                    Header: 'Type produktu',
                    accessor: 'productType',
                },
                {
                    Header: 'Stav',
                    accessor: 'requestState',
                },
                {
                    Header: 'Zaplaceno',
                    accessor: 'paymentAmount',
                },
                {
                    Header: 'Stav platby',
                    accessor: 'paymentState',
                },
                {
                    Header: 'Typ platby',
                    accessor: 'paymentType',
                },                
                {
                    Header: 'Email',
                    accessor: 'paymentEmail',
                    Cell: EditableCell
                },                
                {
                    Header: 'Poznámka',
                    accessor: 'comment',
                    Cell: EditableCell
                },
                {
                    Header: '',
                    accessor: 'viewId',
                    Cell: SendButton
                },
                {
                    Header: 'Doklad',
                    accessor: 'dokladUrl',
                    Cell: DokladButton,
                    width: 60
                }
            ],
        },
    ];