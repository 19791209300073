import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import MainInput, { MainInputType } from "../_base/mainInput";
import CheckBox from "../_base/checkbox";
import ActiveSection, {
  ActiveSectionItem,
} from "../_base/ActiveSection/activeSection";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderValue } from "../../BL/features/orderSlice";
import {
  getOrder,
  handlerSumbitPayAction,
  redirectToHome,
} from "../../BL/orderBL";
import BaseInput from "../_base/baseInput";
import { DetailTicketPrice, SectionFindType } from "../../models/orderModel";
import Moment from "react-moment";
import ShowMessage, { MessageType } from "../_base/showMessage";
import { IcValidator } from "../../BL/validator";
import { selectDetailList } from "../../BL/features/detailSlice";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ScrollToError } from "../_base/scrollToTop";
import ChangeTitleDescription from "../_base/titleDescChanger";

export default function OrderPayment() {
  const { id } = useParams<{ id: string | undefined }>();
  const validSchema = createValidationSchema();
  const history = useHistory();
  const oldVal = useSelector(selectOrderValue);
  const [ordData, setOrdData] = useState(oldVal);
  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [setNoOfVerifications] = useState(0);
  const [actionToChange, setActionToChange] = useState("");
  const [dynamicAction, setDynamicAction] = useState("payment");

  const detailListVal = useSelector(selectDetailList);

  if (
    detailListVal &&
    detailListVal.value &&
    detailListVal.value.length > 0 &&
    (ordData.causeIds == null || ordData.causeIds.length <= 0)
  ) {
    ordData.id = id;
    ordData.causeIds = detailListVal.value
      .map((x) => x.cause_id)
      .reduce((prev, curr) => prev + "|" + curr);
    ordData.initType = SectionFindType.Detail;
    ordData.price = detailListVal.value.length * DetailTicketPrice;

    //console.log(ordData.causeIds);
  }

  ordData.token = token;

  redirectToHome(oldVal.id, id, history);

  useEffect(() => {
    if (ordData.causeIds == null || ordData.causeIds.length <= 0)
      getOrder(id, dispatch, setOrdData, history);

    ChangeTitleDescription(
      "Online platba | Cebia.cz",
      "Okamžitá kontrola exekucí fyzických i právnických osob v Centrální evidenci exekucí ČR. Informace o počtu vedených exekucí i výše dlužných částek."
    );
  }, [id]);

  function createValidationSchema() {
    Yup.addMethod(Yup.string, "icValidator", function yupIc(msg) {
      return this.test("icValTest", msg, function (value) {
        const isValid = IcValidator(value);
        return isValid;
      });
    });

    return Yup.object({
      email: Yup.string()
        .email("Nesprávny formát Email-u.")
        .required("Prosím zadejte položku E-mail."),
      invoiceData: Yup.object({
        invoiceCompany: Yup.string().max(100, "Maximální počet znaků je 100."),
        invoiceIc: Yup.string()
          .max(11, "Maximální počet znaků je 11.")
          .icValidator("Nesprávna hodnota IČ."),
        invoiceDic: Yup.string().max(12, "Maximální počet znaků je 12."),
        invoiceStreet: Yup.string().max(100, "Maximální počet znaků je 100."),
        invoiceCity: Yup.string().max(100, "Maximální počet znaků je 100."),
        invoicePsc: Yup.string().max(5, "Maximální počet znaků je 5."),
      }),
    });
  }

  function isApple() {
    if (window.navigator.userAgent.toLowerCase().indexOf("os x") > 0) {
      return true;
    }

    return false;
  }

  function isIphone() {
    if (window.navigator.userAgent.toLowerCase().indexOf("iphone") > 0) {
      return false;
    }

    return true;
  }

  async function CallCaptcha() {
    if (!executeRecaptcha) {
      return "";
    }

    const result = await executeRecaptcha("dynamicAction");
    setToken(result);

    return result;
  }

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setNoOfVerifications, setToken]
  );

  return (
    <>
      <section className="Section Section--bare">
        <div className="Container">
          <div className="exekuce-PageHeader">
            <h1 className="exekuce-PageHeader-title Alfa">Online platba</h1>
          </div>
        </div>
      </section>
      <ShowMessage
        content="Platební proces nebyl dokončen, opakujte výběr a zadání platby."
        isVisible={ordData?.isPayed < 0}
        type={MessageType.Error}
      />
      <section className="Section Section--appended Section--medium">
        <div className="Container">
          <Formik
            initialValues={ordData}
            enableReinitialize
            validationSchema={validSchema}
            onSubmit={(values) => {
              CallCaptcha().then((token) => {
                handlerSumbitPayAction(values, dispatch, history, token!);
                ordData.isPayed = -1;
              });
            }}
          >
            <Form className="Form">
              <div className="Section Section--medium">
                <div className="Container mb-30">
                  <div className="Form-headline lg:mb-40 text-left">
                    {ordData.initType === SectionFindType.Detail ? (
                      <>
                        Dokupovaní detailů počet: {detailListVal.value.length}
                      </>
                    ) : (
                      <>
                        Prověřovaný subjekt:
                        {ordData.firstName != undefined &&
                          ordData.firstName.length > 0 && (
                            <strong className="pl-5">
                              {ordData.firstName} {ordData.lastName},
                              <Moment format="DD. MM. YYYY" className="pl-5">
                                {ordData.bornDate?.toString()}
                              </Moment>
                            </strong>
                          )}
                        {ordData.rc != undefined && ordData.rc.length > 0 && (
                          <span>
                            {ordData.firstName?.length > 0 && ", "} R.Č.:
                            <strong className="pl-5">{ordData.rc}</strong>
                          </span>
                        )}
                        {ordData.initType === SectionFindType.Ic &&
                          ordData.ic != undefined &&
                          ordData.ic.length > 0 && (
                            <>
                              <span className="pl-5">
                                IČ.: <strong>{ordData.ic}</strong>
                              </span>
                              <div className="text-left font-small">
                                <p>
                                  <strong className="color-red pr-5">
                                    Upozornění:
                                  </strong>
                                  Správnost údajů není ověřována, důkladně je
                                  prosím zkontrolujte. V případě chybného zadání
                                  nelze požadovat vrácení peněz.
                                </p>
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </div>
                  <div className="Separator mb-30"></div>
                  <div className="Grid gap-30 xxl:gap-50 gmb-20 xxl:gmb-30 mb-0">
                    <div className="FormItem smw:w-6/12 xl:w-4/12 has-error">
                      <BaseInput
                        name="email"
                        label="Kontaktní email"
                        inputType={MainInputType.email}
                        placeholder="Zadejte svůj email"
                        question="Na uvedenou e-mailovou adresu Vám po provedení úhrady budou zaslány informace potřebné pro zobrazení zaplacených informací a zjednodušený daňový doklad k platbě."
                      />
                    </div>
                  </div>

                  {/* <ActiveSection id="actInvoice" headLabel="Chcete vyplnit fakturační údaje?" question="Společně s informacemi potřebnými pro zobrazení zaplacených informací obdržíte vždy i zjednodušený (neadresný) daňový doklad. Pokud chcete mít na daňovém dokladu vyplněny Vaše fakturační údaje (firmu, adresu, IČ, DIČ), zadejte je prosím níže." >
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoiceCompany" label="Název společnosti" placeholder="Název společnosti"  />
                                        </ActiveSectionItem>
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoiceIc" label="IČ" placeholder="Identifikační číslo firmy" />
                                        </ActiveSectionItem>
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoiceDic" label="DIČ" placeholder="Daňové identifikační číslo" />
                                        </ActiveSectionItem>
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoiceStreet" label="Ulice" placeholder="Sídlo společnosti" />
                                        </ActiveSectionItem>
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoiceCity" label="Město" placeholder="Město/obec"/>
                                        </ActiveSectionItem>
                                        <ActiveSectionItem>
                                            <BaseInput name="invoiceData.invoicePsc" label="PSČ" placeholder="PSČ"/>
                                        </ActiveSectionItem>
                                    </ActiveSection> */}

                  <div className="Section Section--slim">
                    <div className="FormPayment">
                      <div className="Form-subtitle lg:mb-40 just-flex-start">
                        Vyberte platební metodu{" "}
                        <span
                          aria-hidden="true"
                          className="VisualIcon VisualIcon--goPay"
                        ></span>
                      </div>
                      <div className="Grid gmb-20 lg:gmb-10">
                        <label className="Radio">
                          <Field
                            className="Radio-input"
                            type="radio"
                            name="paymentType"
                            value="PAYMENT_CARD"
                          />
                          <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle">
                            <div className="FormPayment-title w-2/3 smw:w-1/2 md:w-4/12">
                              <div className="Radio-placeholder"></div>
                              On-line platební kartou
                            </div>
                            <div className="FormPayment-icons w-1/3 smw:w-1/2 md:w-4/12">
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--visa"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--visaElectron"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--mastercard"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--maestro"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--mastercardElectronic"
                              ></span>
                            </div>
                            <div className="FormPayment-note md:w-4/12 lg:text-right">
                              Výsledky jsou k dispozici hned po zaplacení
                            </div>
                          </div>
                        </label>

                        {isIphone() && (
                          <label className="Radio">
                            <Field
                              className="Radio-input"
                              type="radio"
                              name="paymentType"
                              value="GPAY"
                            />
                            <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle">
                              <div className="FormPayment-title w-2/3 smw:w-1/2 md:w-4/12">
                                <div className="Radio-placeholder"></div>
                                Google Pay
                              </div>
                              <div className="FormPayment-icons w-1/3 smw:w-1/2 md:w-4/12">
                                <span
                                  aria-hidden="true"
                                  className="VisualIcon VisualIcon--googlePay"
                                ></span>
                              </div>
                              <div className="FormPayment-note md:w-4/12 lg:text-right">
                                Výsledky jsou k dispozici hned po zaplacení
                              </div>
                            </div>
                          </label>
                        )}

                        {isApple() && (
                          <label className="Radio">
                            <Field
                              className="Radio-input"
                              type="radio"
                              name="paymentType"
                              value="APPLE_PAY"
                            />
                            <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle">
                              <div className="FormPayment-title w-2/3 smw:w-1/2 md:w-4/12">
                                <div className="Radio-placeholder"></div>
                                Apple Pay
                              </div>
                              <div className="FormPayment-icons w-1/3 smw:w-1/2 md:w-4/12">
                                <span
                                  aria-hidden="true"
                                  className="VisualIcon VisualIcon--applePay"
                                ></span>
                              </div>
                              <div className="FormPayment-note md:w-4/12 lg:text-right">
                                Výsledky jsou k dispozici hned po zaplacení
                              </div>
                            </div>
                          </label>
                        )}

                        <label className="Radio">
                          <Field
                            className="Radio-input"
                            type="radio"
                            name="paymentType"
                            value="BANK_ACCOUNT"
                          />
                          <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle">
                            <div className="FormPayment-title w-2/3 smw:w-1/2 md:w-4/12">
                              <div className="Radio-placeholder"></div>
                              On-line převod
                            </div>
                            <div className="FormPayment-icons w-1/3 smw:w-1/2 md:w-4/12">
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--ceskaSporitelna"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--rb"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--kb"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--csob"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--fio"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--mbank"
                              ></span>
                              <span
                                aria-hidden="true"
                                className="VisualIcon VisualIcon--andMore"
                              ></span>
                            </div>
                            <div className="FormPayment-note md:w-4/12 lg:text-right">
                              Výsledky jsou k dispozici hned po zaplacení
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Separator"></div>
                  <Field type="hidden" name="token" />
                  <div className="Section Section--slim Section--prepended">
                    <div className="FormPaymentFooter Grid gap-30 mb-20">
                      <div className="Grid-cell md:w-8/12">
                        <div className="FormPaymentFooter-blueBlock">
                          <ul className="List List--circleCheck">
                            <li>Kontrola v Centrální evidenci exekucí ČR</li>
                            <li>Výsledek ihned k dispozici online i v PDF</li>
                            <li>
                              Možnost prověření detailu všech nalezených exekucí
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Grid-cell md:w-4/12">
                        <div className="FormPaymentFooter-submitBlock">
                          <span className="FormPaymentFooter-priceLabel pt-20 md:pt-10">
                            Cena za prověření:
                          </span>
                          <div className="Price Price--small mb-5 md:mb-20">
                            {ordData.price} Kč
                          </div>
                          {/* <FindSubmitButton label="Koupit" classes="Button--block" /> */}
                          <button
                            className="Button Button--block"
                            type="submit"
                          >
                            <span className="Button-content">Koupit</span>
                          </button>
                          <GoogleReCaptcha
                            action={dynamicAction}
                            onVerify={handleReCaptchaVerify}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-right">
                    Pokračovánim v nákupu souhlasíte a potvrzujete, že jste se
                    seznámil/a se{" "}
                    <a
                      href="https://www.cebia.cz/files/vseobecne_podminky_kontrola_exekuci.pdf"
                      target="_blank"
                    >
                      Všeobecnými podmínkami
                    </a>
                    <br />
                    Informace o zpracování{" "}
                    <a
                      href="https://www.cebia.cz/pravni-informace/osobni-udaje"
                      target="_blank"
                    >
                      osobních údajů
                    </a>
                  </p>
                </div>
              </div>
              <ScrollToError scrollToFormElement="email" />
            </Form>
          </Formik>
        </div>
      </section>
    </>
  );
}
