import React, { useState } from "react";
import { ErrorMessage, Field, useField } from "formik";
import { number } from "yup/lib/locale";

export enum MainInputType {
  text = "text",
  date = "date",
  number = "number",
  email = "email",
  password = "password",
}

export enum MainInputComponent {
  input = "input",
  textarea = "textarea",
  select = "select",
}

interface ImainInput {
  inputType?: MainInputType;
  component?: MainInputComponent;
  name: string;
  label?: string;
  labelSub?: string;
  placeHolder?: string;
  disabled?: boolean;
  maxLenght?: number;
  inputFilter?: string;
  handlerOnChange?: (e: React.ChangeEvent<any>) => void;
}

const MainInput: React.FC<ImainInput> = ({
  inputType = MainInputType.text,
  component = MainInputComponent.input,
  name,
  label,
  labelSub,
  placeHolder,
  disabled,
  maxLenght,
  inputFilter,
  handlerOnChange,
}) => {
  const [field, meta] = useField(name);
  const [isFocused, setFocused] = useState(false);

  function change(e: any) {
    if (inputFilter?.length ?? 0 > 0)
      e.value = e.value.replace(new RegExp(inputFilter ?? "", ""), "");
  }

  return (
    <>
      <label
        className={`Input2 ${
          !(meta.value || isFocused || inputType === MainInputType.date) &&
          "is-empty"
        }`}
      >
        <Field
          name={name}
          component={component}
          disabled={disabled}
          className={`Input2-input ${
            meta.touched && meta.error ? "is-invalid" : ""
          }`}
          type={inputType}
          maxLength={maxLenght}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyUp={(e: React.ChangeEvent<any>) => {
            if (handlerOnChange) {
              handlerOnChange(e);
            }
          }}
          max="9999-12-31"
          onInput={(e: any) => change(e.target)}
        />
        {label && (
          <span className="Input2-label">
            {label}{" "}
            {labelSub && <span className="Input2-label-sub">{labelSub}</span>}
          </span>
        )}
        {placeHolder && (
          <div className="Input2-helper Input2-helper--text smMax:u-hidden">
            {placeHolder}
          </div>
        )}
        {inputType === MainInputType.date && (
          <div className="Input2-helper">
            <span aria-hidden="true" className="Icon Icon--calendar"></span>
          </div>
        )}
      </label>
      <ErrorMessage name={name} component="div" className="InputError" />
    </>
  );
};

export default MainInput;
