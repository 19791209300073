import React, { Dispatch } from 'react';
import { admin, signoutReq } from "./http-common";
import { hideLoader, showLoader } from './features/loaderSlice';
import UserIdentity from '../models/userModel';
import { dispatchingUpdateModel, orderGridItem, ordersGridData } from '../models/administModel'
import { setUserIdentity } from './features/userSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';


//TODO!!!!DELETE!!! -- BEGIN
export const submitLoginAction = (username: string, userpass: string, history: any, dispatch: Dispatch<any>) => {
    let destUrl = "/showItems";

    dispatch(showLoader());

    admin.post<UserIdentity>("../Login/SignIn", { username, userpass }).then(response => {
        if (response.status === 200) {

            dispatch(setUserIdentity(response.data));
            history.push(destUrl);

        }
        else {
            //vynulovani prihlaseneho usera.
            dispatch(setUserIdentity(new UserIdentity()));
        }

        dispatch(hideLoader());
    });
}
//TODO!!!!DELETE!!! -- END

export async function getUser() {
    const dispatch = useDispatch();
    const history = useHistory();

    await admin.get<UserIdentity>("../Login/GetUser").then(response => {
        if (response.status === 200 && response.data.isAuthenticated) {
            dispatch(setUserIdentity(response.data));
        }
        else {
            //vynulovani prihlaseneho usera.
            //dispatch(setUserIdentity(new UserIdentity()));
            const redirectUrl = `${process.env.PUBLIC_URL}/login/signIn`;
            window.location.replace(redirectUrl);
        }
    });

    dispatch(hideLoader());
    history.push("/showItems");
}

export function signOut() {
    admin.get<string>("../Login/SignOut").then(response => {

        if (response.data && response.data.length > 0) {
            window.location.assign(response.data);
        }

    });
}


export function getOrderDatas(setOrdData: React.Dispatch<React.SetStateAction<any[]>>): ordersGridData {
    let result: ordersGridData | null = null;

    admin.post<ordersGridData>("/getData").then(response => {
        if (response.status === 200) {

            result = response.data;

            setOrdData(result.orders!);
        }
    });

    return result!;
}


export function updateCell(viewId:string,cellName:string,cellValue:string){

    let model = new dispatchingUpdateModel();
    model.viewId = viewId;
    model.propertyName = cellName;
    model.propertyValue = cellValue;

    admin.post<boolean>("/UpdateProp",model).then(response => {
        if (response.status === 200) {

        }
    });
}