import React, { createContext, useContext } from 'react';
import { getUser, signOut } from '../../BL/adminBL'




export default function AuthPageSignIn() {
    getUser();

    return (<></>)
}

export function AuthPageSignOut() {

    signOut();

    return (<></>)
}