import React from 'react';
import { Route } from 'react-router-dom';
import FindByRc from '../../components/home/findByRc';
import FindByName from './findByName';
import FindByIc from './findByIc';

export default function CreateOrderForm() {

  return (
    <>
      <Route exact path="/" component={FindByName} />
      {/* <Route path="/" exact component={FindByName} /> */}
      <Route path="/rc" component={FindByRc} />
      <Route path="/ic/:ic?" component={FindByIc} />
    </>
  )
}
