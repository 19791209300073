import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MainNavSection from "./baseFindControl";
import FindSubmitButton from "../_base/findSubmitControl";
import MainInput, { MainInputType } from "../_base/mainInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { OrderValues, SectionFindType } from "../../models/orderModel";
import { useSelector, useDispatch } from "react-redux";
import { handlerSubmitAction, setFindValue } from "../../BL/orderBL";
import {
  setSectionFindType,
  selectOrderValue,
} from "../../BL/features/orderSlice";
import ChangeTitleDescription, {
  tmpDesc,
  tmpTitle,
} from "../_base/titleDescChanger";

declare module "yup" {
  export interface DateSchema {
    dateValidator(msg?: string): StringSchema;
  }
}

export default function FindByName(props: any) {
  const history = useHistory();
  const validSchema = createValidationSchema();
  const oldVal = useSelector(selectOrderValue);
  const dispatch = useDispatch();
  setFindValue(SectionFindType.Name, dispatch, oldVal);

  useEffect(() => {
    ChangeTitleDescription(
      "Kontrola exekucí – Centrální evidence exekucí ČR| Cebia.cz",
      "Okamžitá kontrola exekucí fyzických i právnických osob v Centrální evidenci exekucí ČR. Informace o počtu vedených exekucí i výše dlužných částek."
    );
  }, [tmpTitle, tmpDesc]);

  function createValidationSchema() {
    Yup.addMethod(Yup.date, "dateValidator", function yupDate(msg) {
      return this.test("dateValTest", msg, function (value) {
        const isNamePath =
          !window.location.pathname.toLowerCase().includes("/ic") &&
          !window.location.pathname.toLowerCase().includes("/rc");
        let isValid = true;
        return !isNamePath || isValid;
      });
    });

    return Yup.object({
      firstName: Yup.string()
        .max(64, "Maximální počet znaků je 64.")
        .required("Prosím zadejte položku Jméno"),
      lastName: Yup.string()
        .max(64, "Maximální počet znaků je 64.")
        .required("Prosím zadejte položku Příjmení"),
      bornDate: Yup.date()
        .dateValidator("Nesprávna hodnota Datum narození.")
        .required("Prosím zadejte položku Datum narození")
        .nullable(),
    });
  }

  return (
    <>
      <Formik
        initialValues={oldVal}
        validationSchema={validSchema}
        onSubmit={(values: OrderValues) =>
          handlerSubmitAction(values, dispatch, history)
        }
      >
        <Form className="homepage-Form" data-component="HomepageForm">
          <div className="homepage-Form-header">
            <MainNavSection secType={SectionFindType.Name} />
          </div>
          <div className="homepage-Form-main">
            <div className="homepage-Form-controls">
              <div className="Grid gap-10 gmb-5">
                <div className="Grid-cell smw:w-1/2 md:w-1/3">
                  <MainInput
                    name="firstName"
                    label="Jméno"
                    labelSub="(s diakritikou)"
                    //disabled={true}
                  />
                </div>
                <div className="Grid-cell smw:w-1/2 md:w-1/3">
                  <MainInput
                    name="lastName"
                    label="Příjmení"
                    labelSub="(s diakritikou)"
                    //disabled={true}
                  />
                </div>
                <div className="Grid-cell md:w-1/3">
                  <MainInput
                    name="bornDate"
                    label="Datum narození"
                    inputType={MainInputType.date}
                    //disabled={true}
                  />
                </div>
              </div>
            </div>
            <FindSubmitButton label="Vyhledat" />
          </div>
        </Form>
      </Formik>
    </>
  );
}
