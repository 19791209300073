import BaseInput from '../_base/baseInput'
import { MainInputType } from '../_base/mainInput'
import { ExekuceTicketPrice } from '../../models/orderModel';
import { useSelector } from 'react-redux';
import { selectOrderValue } from '../../BL/features/orderSlice';

export default function SubjectRc() {
    const oldVal = useSelector(selectOrderValue);

    return (
        <>
            <div className="Section Section--medium lg:pb-50">
                <div className="Container">
                    <div className="Form-headline text-left">
                        Prověřované rodné číslo: <strong>{oldVal.rc}</strong>
                    </div>
                </div>
                <div className="Container text-left">
                    <p><strong className="color-red">Upozornění:</strong> Zkontrolujte prosím správnost zadaných údajů. Po zaplacení kontroly je již nelze změnit.</p>
                </div>
            </div>
            <div className="Container">
                <div className="Separator"></div>
            </div>
            <div className="Section Section--slim">
                <div className="Container">
                    <h2 className="Form-subtitle smaller text-left">Zkontrolovat subjekt i podle jména <span className="color-orange">(+ {ExekuceTicketPrice} Kč)</span></h2>
                    <div className="Grid gap-20 lg:gap-50 gmb-20">
                        <div className="FormItem smw:w-6/12 md:w-4/12">
                            <BaseInput name="firstName" label="Jméno" />
                        </div>
                        <div className="FormItem smw:w-6/12 md:w-4/12">
                            <BaseInput name="lastName" label="Příjmení" />
                        </div>
                        <div className="FormItem smw:w-6/12 md:w-4/12">
                            <BaseInput name="bornDate" label="Datum narození" inputType={MainInputType.date} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="Container">
                <div className="Separator"></div>
            </div>
        </>
    )
}