// import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import "./css/main.css";
import "./css/custom.css";
import App from "./App";
import SubscriberForm from "./components/_base/subscriber";
import { Provider } from "react-redux";
import store from "./BL/store";
import cebiaWhiteLogo from "./assets/images/logo-cebia-white.svg";
import giantLogo from "./assets/images/pages/logo-giant.svg";
import LoadingComponent from "./components/_base/LoadingComponent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById("exekuceContainer");
ReactDOM.render(
  <>
    <button type="button" className="NavToggle" data-component="NavOpener">
      <span className="NavToggle-label">Menu</span>
      <span className="Hamburger">
        <span className="Hamburger-content">
          <span className="Hamburger-line"></span>
          <span className="Hamburger-line"></span>
          <span className="Hamburger-line"></span>
        </span>
      </span>
    </button>
    <div id="lightboxOverlay" className="overlay"></div>

    <div className="scroll-Container">
      <div className="scroll-Container-inner">
        <div className="scroll-Container-content">
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey="6LemncUaAAAAALsUprLGQQAbyDcxgZG1jb2Ts_V1"
            scriptProps={{ async: true, defer: true, appendTo: "body" }}
          >     
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Provider store={store}>
                <LoadingComponent />
                <App />
              </Provider>
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </div>
      </div>

      <div className="scroll-Container-footer">
        <div className="FooterWrapper" data-ref="wrapper">
          <footer className="Footer" id="footer">
            <div className="Container Container--wide">
              <div className="Container is-pure smwMax:u-hidden">
                <div className="Footer-intro">
                  <img
                    src={cebiaWhiteLogo}
                    className="Footer-logo"
                    width="130"
                    height="41"
                    alt="cebia"
                  />
                  <p className="Footer-contacts">
                    <a href="mailto:exekuce@cebia.cz">exekuce@cebia.cz</a>
                  </p>
                  <p className="Footer-social">
                    <a
                      href="https://www.facebook.com/cebia.cz"
                      rel="noopener"
                      className="Social Social--facebook"
                    >
                      <span className="Social-label">Facebook</span>
                    </a>
                    <a
                      href="https://www.youtube.com/user/cebiacz"
                      rel="noopener"
                      className="Social Social--youtube"
                    >
                      <span className="Social-label">YouTube</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="Separator smwMax:u-hidden"></div>
              <div className="Container is-pure">
                <div className="Footer-content">
                  <div className="Footer-block">
                    <ul className="Footer-list">
                      <li>
                        <a href="https://www.cebia.cz/autorizovana-pracoviste" target="_blank">
                          Autorizovaná pracoviště
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/kontakty" target="_blank">Kontakty</a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/o-nas#media" target="_blank">Pro média</a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/o-nas#volne-pozice" target="_blank">
                          Kariéra
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/overeni-osvedceni" target="_blank">
                          Ověření osvědčení
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/helpdesk-ocis" target="_blank">
                          HelpDesk OCIS
                        </a>
                      </li>
                       <li>
                        <a href="https://www.cebia.cz/pruvodce/partner-eu" target="_blank">
                          Partner EU
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/pravni-informace" target="_blank">
                          Právní informace
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/kontakty#reklamace" target="_blank">
                          Reklamace a stížnosti
                        </a>
                      </li>
                      <li>
                        <a href="http://cebia.com/checklease/frmHledej.aspx" target="_blank">
                          CheckLease
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebianet.cz" target="_blank">CebiaNET</a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/mapa-stranek" target="_blank">
                          Mapa stránek
                        </a>
                       </li>
                       <li>
                           <a href="https://www.cebia.cz/pravni-informace/cookies" target="_blank">
                               Nastavení cookies
                           </a>
                       </li>
                      {/*<li>*/}
                      {/*  <a href="https://www.cebia.cz/sluzby">*/}
                      {/*    Služby*/}
                      {/*  </a>*/}
                      {/*</li>*/}
                    </ul>
                  </div>

                  <div className="Footer-block Footer-block--services">
                    <h3 className="Footer-blockTitle">
                      <a href="https://www.cebia.cz/sluzby" target="_blank">Služby</a>
                    </h3>
                    <ul className="Footer-list Footer-list--minor">
                      <li>
                        <a href="https://cz.cebia.com" target="_blank">
                          Online prověření historie AUTOTRACER
                        </a>
                      </li>
                      <li>
                          <a href="https://www.cebia.cz/cebia-smart-kod" target="_blank">
                              Cebia Smart kód
                          </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/provin" target="_blank">
                          Prověření v zemi původu PROVIN
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/vintest" target="_blank">
                          Fyzická kontrola VIN a identifikátorů VINTEST
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/piskovani-skel" target="_blank">
                          Pískování skel OCIS
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/leptani-skel" target="_blank">
                          Leptání skel OCIS
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/cebia-sat" target="_blank">
                          Satelitní zabezpečení
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/uniqa-safeline" target="_blank">
                          UNIQA SafeLine
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/allianz-bonusdrive" target="_blank">
                          Allianz BonusDrive
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebia.cz/sluzby/vinfoto" target="_blank">
                          Prohlídka k pojištění vozidla VINFOTO
                        </a>
                        </li>
                        <li>
                            <a href="https://www.cebia.cz/sluzby/cebia-foto" target="_blank">
                                Mobilní aplikace Cebia Foto
                            </a>
                        </li>
                      <li>
                        <a href="https://www.carolina.cz" target="_blank">
                          Mobilní aplikace Carolina
                        </a>
                      </li>
                      <li>
                        <a href="https://www.cebianet.cz" target="_blank">Pro partnery</a>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="Footer-block Footer-block--newsletter"
                    id="snippet--newsletterFormSnippet"
                  >
                    <h3 className="Footer-blockTitle lgMax:u-hidden">
                      Přihlaste se k odběru novinek
                    </h3>
                    <p className="Footer-blockTitle xl:u-hidden">
                      Odběr novinek
                    </p>
                    <SubscriberForm />
                  </div>
                </div>
                <div className="Separator"></div>
                <div className="Footer-info">
                  <p className="Footer-copyright">
                    © {new Date().getFullYear()}, Cebia, spol. s r.o.
                  </p>
                  <p className="Footer-giant">
                    <a
                      href="https://www.giant.cz"
                      className="Giant"
                      aria-label="Giant interactive s.r.o."
                    >
                      Made by
                      <img src={giantLogo} width="29" height="27" alt="Giant" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </>,
  rootElement
);

//registerServiceWorker();
