import {
    createContext,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import TagManager from 'react-gtm-module'

type NavigationContextOptions = {
    isOpen: boolean
    open: () => void
    close: () => void
    toggle: () => void
}

const initialValue: NavigationContextOptions = {
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    open: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    close: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggle: () => {},
}

const NavigationContext = createContext<NavigationContextOptions>(initialValue)

export type NavigationProviderProps = {
    children?: ReactNode
}

export const NavigationProvider = ({
    children,
}: NavigationProviderProps): ReactElement<React.Provider<NavigationContextOptions>> => {
    const [isOpen, setIsOpen] = useState(false)
    const [pathName, setPathName] = useState(window.location.href)
    const [refName, setRef] = useState(document.referrer)
    const open = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const toggle = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const contextValue = useMemo<NavigationContextOptions>(
        () => ({
            isOpen,
            open,
            close,
            toggle,
        }),
        [isOpen, setIsOpen, open, close, toggle],
    )

    useEffect(() => {
        setPathName(window.location.href)
    })

    useEffect(() => {
        const oldRef = refName
        setRef(pathName)
            console.log('SEND URL:', pathName)
            console.log('oldRef URL:', oldRef)
            TagManager.dataLayer({
                dataLayer: {
                    event: 'page_view',
                    page: { referrer: oldRef },
                    url: pathName,
                    clear: true,
                },
            })
    }, [pathName])

    return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>
}

export const useNavigation = (): NavigationContextOptions => useContext(NavigationContext)
