import React, { Component } from 'react'

export class Benefits extends Component {
    render() {
        return (            
            <div className="Section Section--bare SectionHomepageBenefits">
                <div className="Container Container--medium">
                <div className="FadedBackground FadedBackground--onlyDesktop">
                    <section className="Section SectionHomepageBenefits-section">
                        <div className="Container SectionHomepageBenefits-container">    
                            <div className="Grid gap-10 smw:gap-30 lg:gap-60 gmb-30">
                                <div className="Grid-cell w-1/2 md:w-1/4">
                                    <div className="Benefit">
                                        <span className="VisualIcon VisualIcon--websiteCheck" aria-hidden="true"></span>
                                        <p>Oficiální registr exekucí <br/> Exekutorské komory ČR</p>
                                    </div>
                                </div>
                                <div className="Grid-cell w-1/2 md:w-1/4">
                                    <div className="Benefit">
                                        <span className="VisualIcon VisualIcon--tabletPenCross" aria-hidden="true"></span>
                                        <p>Bez nutnosti registrace</p>
                                    </div>
                                </div>
                                <div className="Grid-cell w-1/2 md:w-1/4">
                                    <div className="Benefit">
                                        <span className="VisualIcon VisualIcon--glassText" aria-hidden="true"></span>
                                        <p>Možnost zobrazení detailu všech exekucí</p>
                                    </div>
                                </div>
                                <div className="Grid-cell w-1/2 md:w-1/4">
                                    <div className="Benefit">
                                        <span className="VisualIcon VisualIcon--tabletPdf" aria-hidden="true"></span>
                                        <p>Výsledek kontroly ihned k dispozici online</p>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </section>
                    <div className="Section Section--appended">
                        <div className="Container">
                            <div className="Separator"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
 }

export default Benefits;
