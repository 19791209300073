import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import nakup from '../../../assets/images/pages/sideMenu/nakup.jpg';
import nakupWebP from '../../../assets/images/pages/sideMenu/nakup.webp';
import pujcka from "../../../assets/images/pages/sideMenu/pujcka.jpg";
import pujckaWebP from "../../../assets/images/pages/sideMenu/pujcka.webp";
import dluhy from "../../../assets/images/pages/sideMenu/dluhy.jpg";
import dluhyWebP from "../../../assets/images/pages/sideMenu/dluhy.webp";
import partner from "../../../assets/images/pages/sideMenu/partner.jpg";
import partnerWebP from "../../../assets/images/pages/sideMenu/partner.webp";
import pronajem from "../../../assets/images/pages/sideMenu/pronajem.jpg";
import pronajemWebP from "../../../assets/images/pages/sideMenu/pronajem.webp";

export interface SideMenuInterface {
    id: string;
    isRightSide: boolean;
}

interface IState {
    scaleY: number;

}

const zeroPossitionSacelY = 0.211765;
const scaleYIndex = 0.152941;

const sideMenuItems = [{ id: "nakup-vozidla", title: "Nákup vozidla či nemovitosti", clsName: "is-active" },
{ id: "neschvaleni-pujcky", title: "Neschválení půjčky", clsName: "is-hidden" },
{ id: "kontrola-stavu-dluhu", title: "Kontrola stavu dluhů", clsName: "is-hidden" },
{ id: "kontrola-partnera", title: "Kontrola partnera/partnerky", clsName: "is-hidden" },
{ id: "pronajem-nemovitosti", title: "Pronájem nemovitosti", clsName: "is-hidden" }];

export default class SideMenu extends Component<SideMenuInterface, IState> {
    constructor(props: SideMenuInterface) {
        super(props);

        this.state = { scaleY: scaleYIndex };
    }

    isActiveAnchor(hashUrl: String, index: number): boolean {
        let result = false;

        if (hashUrl.length > 0) {
            result = hashUrl === window.location.hash || (window.location.hash.length === 0 && index === 0);
        }

        if (result) {
            //tady nefunguje tenhle zakomenteny radek na prvni click.Musel jsem pouzit setState ale to se muze volat jenom jednou proto podminka na rozdilne hodnoty.
            //urcite se to da vyresit jinak.ted nevim jak.componentDidUpdate nefunguje protoze se to zavola driv
            // this.state.scaleY = (zeroPossitionSacelY + scaleYIndex * index);
            let currentIndex = (zeroPossitionSacelY + scaleYIndex * index);
            if (this.state.scaleY !== currentIndex) {
                this.setState({ scaleY: currentIndex });
            }
        }

        sideMenuItems[index].clsName = (result ? " is-active" : " is-hidden");

        return result;
    }

    render() {
        return (
            <>
                <nav className="homepage-Why-menu">
                    <ul>
                        {
                            sideMenuItems.map((item, index) => (
                                <li key={"sdLiIndex" + index}>
                                    <NavLink to={"#" + item.id} activeClassName="is-active" isActive={() => this.isActiveAnchor("#" + item.id, index)} >{item.title}</NavLink>
                                </li>
                            ))
                        }
                    </ul>
                    <span className="homepage-Why-indicator" aria-hidden="true" data-ref="indicator" style={{ transform: `scaleY(${this.state.scaleY})` }}></span>
                </nav>
                <div className="homepage-Why-slider">
                    <div className={"homepage-WhyCard is-ready" + sideMenuItems[0].clsName} id={sideMenuItems[0].id}>
                        <div className="homepage-WhyCard-image" style={{ background: 'grey' }} >
                            <picture>
                                <source srcSet={nakupWebP} type="image/webp" />
                                <source srcSet={nakup} type="image/jpg" />
                                <img src={nakup} alt="" />
                            </picture>
                        </div>
                        <div className="homepage-WhyCard-content">
                            <p>
                                Při nákupu majetku od osoby v exekuci se kupující vystavuje nebezpečí, že předmět je součástí exekučního řízení a může být novému majiteli zabaven i přesto, že byl koupen legálně a za plnou částku. Vymáhání náhrady škody u původního vlastníka je pak velmi obtížné. Při pořizování vozidla vždy doporučujeme provést kontrolu exekucí.
                            </p>
                        </div>
                    </div>
                    <div className={"homepage-WhyCard is-ready" + sideMenuItems[1].clsName} id={sideMenuItems[1].id}>
                        <div className="homepage-WhyCard-image" style={{ background: 'grey' }}>
                            <picture>
                                <source srcSet={pujckaWebP} type="image/webp" />
                                <source srcSet={pujcka} type="image/jpg" />
                                <img src={pujcka} alt="" />
                            </picture>
                        </div>
                        <div className="homepage-WhyCard-content" data-ref="showAndHide">
                            <p>
                                Pokud vám nebyla schválena půjčka, leasing či hypotéka jedním z důvodu může být i záznam v registru dlužníků, který si finanční instituce vždy prověřují. Záznam v registru dlužníků může být veden bez vašeho vědomí, jelikož finanční instituce většinou neposkytují důvod zamítnutí.
                            </p>
                        </div>
                    </div>
                    <div className={"homepage-WhyCard is-ready" + sideMenuItems[2].clsName} id={sideMenuItems[2].id}>
                        <div className="homepage-WhyCard-image" style={{ background: 'grey' }}>
                            <picture>
                                <source srcSet={dluhyWebP} type="image/webp" />
                                <source srcSet={dluhy} type="image/jpg" />
                                <img src={dluhy} alt="" />
                            </picture>
                        </div>
                        <div className="homepage-WhyCard-content" data-ref="showAndHide">
                            <p>
                                Pokud jste zatíženi větším množstvím dluhů a vedených exekucí, může se stát, že začnete ztrácet přehled o tom, kde a kolik peněz je po vás vymáháno. Abyste se z nepříjemné situace co nejrychleji dostali, je důležité pravidelně kontrolovat registr a pečlivě plánovat postup umořování dluhů.
                            </p>
                        </div>
                    </div>
                    <div className={"homepage-WhyCard is-ready" + sideMenuItems[3].clsName} id={sideMenuItems[3].id}>
                        <div className="homepage-WhyCard-image" style={{ background: 'grey' }}>
                            <picture>
                                <source srcSet={partnerWebP} type="image/webp" />
                                <source srcSet={partner} type="image/jpg" />
                                <img src={partner} alt="" />
                            </picture>
                        </div>
                        <div className="homepage-WhyCard-content" data-ref="showAndHide">
                            <p>
                                Většina párů se vzájemně neptají na své finance a závazky z minulosti. Případné exekuce pak mohou vyjít na povrch až při návštěvě exekutora ve společné domácnosti. Kontrolou exekucí a vyřešením finančních problémů se lze podobným situacím vyvarovat.
                            </p>
                        </div>
                    </div>
                    <div className={"homepage-WhyCard is-ready" + sideMenuItems[4].clsName} id={sideMenuItems[4].id}>
                        <div className="homepage-WhyCard-image" style={{ background: 'grey' }}>
                            <picture>
                                <source srcSet={pronajemWebP} type="image/webp" />
                                <source srcSet={pronajem} type="image/jpg" />
                                <img src={pronajem} alt="" />
                            </picture>
                        </div>
                        <div className="homepage-WhyCard-content" data-ref="showAndHide">
                            <p>
                                Jak zjistit, že je nový nájemník spolehlivý? Jeden z důležitých ukazatelů je jeho schopnost platit řádně a včas nájemné a poplatky. Je-li nájemník zatížen exekucemi, majiteli nemovitosti hrozí nejen nedobytný dlužný nájem, ale také návštěva bytu exekutorem. Kontrola exekucí by proto měla být nedílnou součástí výběru nového nájemníka.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
