import React from 'react';
import { useField } from 'formik';


export interface CheckBoxProps {
    name: string;
    checked?: boolean;
    label: string;
}


export default function CheckBox(props: CheckBoxProps) {
    const [field, meta] = useField(props);

    return (
        <>
            <div className="FormItem FormItem--checkbox">
                <label className="Checkbox" htmlFor={props.name} >
                    <input {...field} id={field.name} type="checkbox" className="Checkbox-input" checked={field.value} />
                    <span className="Checkbox-placeholder"></span>
                    <span className="Checkbox-label" dangerouslySetInnerHTML={{ __html: props.label }}></span>
                </label>
                {meta.touched && meta.error ? (<div className="InputError">{meta.error}</div>) : null}
            </div>
        </>
    )
}
