export const ExekuceTicketPrice = 69;
export const DetailTicketPrice = 69;

export const SearchOptions = [
    { value: "Name", label: "jména", url: "/" },
    { value: "Rc", label: "rodného čísla", url: "/rc" },
    { value: "Ic", label: "IČ", url: "/ic" }
];

export class OrderValues {
    id?: string = undefined;
    initType: SectionFindType = SectionFindType.Name;
    firstName: string = "";
    lastName: string = "";
    bornDate?: Date = undefined;
    rc: string = "";
    ic: string = "";
    product: string = "";
    variable: string = "";
    email: string = "";
    price: number = ExekuceTicketPrice;
    causeIds: string = "";
    invoiceData: InvoiceData = new InvoiceData();
    paymentType: string = "PAYMENT_CARD";
    isPayed: number = 0;
    token: string = "";
    category: string = "";
    value: number = 0;
}

export class InvoiceData {
    invoiceCompany: string = "";
    invoiceIc: string = "";
    invoiceDic: string = "";
    invoiceStreet: string = "";
    invoiceCity: string = "";
    invoicePsc: string = "";
}

export enum SectionFindType {
    Name = 0,
    Rc = 1,
    Ic = 2,
    //je to dokupovani detailu
    Detail = 3
}

export default {
    OrderValues,
    SectionFindType,
    InvoiceData
};

export class DetailOrderItem {
    ex_cislo: string = "";
    cause_id: string = "";
    description: string = "";
}