import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MainNavSection from "./baseFindControl";
import FindSubmitButton from "../_base/findSubmitControl";
import MainInput, { MainInputType } from "../_base/mainInput";
import { Form, Formik } from "formik";
import CheckBox from "../_base/checkbox";
import * as Yup from "yup";
import { SectionFindType } from "../../models/orderModel";
import { useSelector, useDispatch } from "react-redux";
import { handlerSubmitAction, setFindValue } from "../../BL/orderBL";
import {
  setSectionFindType,
  selectOrderValue,
} from "../../BL/features/orderSlice";
import { IcValidator } from "../../BL/validator";
import ChangeTitleDescription, {
  tmpDesc,
  tmpTitle,
} from "../_base/titleDescChanger";

declare module "yup" {
  export interface StringSchema {
    icValidator(msg?: string): StringSchema;
  }
}

export default function FindByIc(props: any) {
  const history = useHistory();
  const urlIc = useParams<{ ic: string }>();
  const validSchema = createValidationSchema();
  const oldVal = useSelector(selectOrderValue);
  const dispatch = useDispatch();
  setFindValue(SectionFindType.Ic, dispatch, oldVal);

  if (urlIc.ic) {
    oldVal.ic = urlIc.ic;
  }

  useEffect(() => {
    ChangeTitleDescription(
      "Kontrola exekucí - podle IČ | Cebia.cz",
      "Okamžitá kontrola exekucí fyzických osob v Centrální evidenci exekucí ČR. Informace o počtu vedených exekucí na IČ i výše dlužných částek."
    );
  }, [tmpTitle, tmpDesc]);

  function createValidationSchema() {
    Yup.addMethod(Yup.string, "icValidator", function yupIc(msg) {
      return this.test("icValTest", msg, function (value) {
        const isIcPath = window.location.pathname.toLowerCase().includes("/ic");
        const isValid = IcValidator(value);

        return !isIcPath || isValid;
      });
    });

    return Yup.object({
      ic: Yup.string()
        .max(8, "Maximální počet znaků je 8.")
        .icValidator("Nesprávna hodnota IČ.")
        .required("Prosím zadejte položku IČ"),
    });
  }

  return (
    <>
      <Formik
        initialValues={oldVal}
        validationSchema={validSchema}
        onSubmit={(values) => {
          handlerSubmitAction(values, dispatch, history);
        }}
      >
        <Form className="homepage-Form" data-component="HomepageForm">
          <div className="homepage-Form-header">
            <MainNavSection secType={SectionFindType.Ic} />
          </div>
          <div className="homepage-Form-main">
            <div className="homepage-Form-controls">
              <div className="Grid">
                <div className="Grid-cell">
                  <MainInput
                    name="ic"
                    label="Zadat IČ"
                    labelSub="(Pouze právnické osoby)"
                    inputFilter="[^\d]"
                    maxLenght={8}
                    //placeHolder="Pouze právnické osoby"
                    //disabled={true}
                  />
                </div>
              </div>
            </div>
            <FindSubmitButton label="Vyhledat" />
          </div>
        </Form>
      </Formik>
      <label className="redLabel">
        Podle IČ lze vyhledávat jen právnické osoby (firmy)!{" "}
        <strong>Živnostníky</strong> lze vyhledávat pouze podle rodného čísla
        nebo jména.
      </label>
    </>
  );
}
