import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-cebia.svg';
import { getUserIdentity } from '../../BL/features/userSlice'
import { useSelector } from 'react-redux';

export default function mainMenu() {
    const userIdentity = useSelector(getUserIdentity);
    const [currState, setCurrState] = useState({ isFocused: false, focusedName: "", navOpened: false, openedSubMenu: false });

    function handleClick() {
        var val = !currState.navOpened;
        setCurrState({ ...currState, navOpened: val });

        if (val === false) {
            setCurrState({ ...currState, openedSubMenu: false, focusedName: "", navOpened: val });
        }
    }

    function setIsFocused(e: any, val: any) {
        if (!currState.openedSubMenu) {
            setCurrState({ ...currState, isFocused: val, focusedName: e.target.getAttribute('data-rel') });
        }
    }

    function getClass(data: any) {
        return ((currState.isFocused && currState.focusedName === data) || (currState.openedSubMenu && currState.focusedName === data)) ? " is-active" : "";
    }

    function openSubmenu(e: any) {
        var val = !currState.openedSubMenu;

        setCurrState({ ...currState, openedSubMenu: val, focusedName: val ? e.target.getAttribute('data-rel') : "" });

        if (e.target.getAttribute('data-rel') !== null) {
            e.preventDefault();
            return false;
        }
        if (e.target.getAttribute('data-ref') !== null) {
            window.open(e.target.getAttribute('href'), "_self");
        }
    }

    return (
        <>
            <div className="Header">
                <div className="Container Container--full">
                    <div className="Header-content">
                        <div className="Header-logo">
                            <a href="/?anim=1"><img src={Logo} width="130" height="41" alt="cebia" /></a>
                        </div>
                        <nav className={"Nav" + (currState.navOpened ? " is-visible is-active" : "") + (currState.openedSubMenu ? " is-subnavActive" : "")} aria-label="nav-main" data-component="Nav">
                            <button type="button" className={"NavToggle NavToggle--static" + (currState.navOpened ? " is-active" : "")} data-component="NavOpener" onClick={() => handleClick()}>
                                <span className="NavToggle-label">Menu</span>
                                <span className="Hamburger">
                                    <span className="Hamburger-content">
                                        <span className="Hamburger-line"></span>
                                        <span className="Hamburger-line"></span>
                                        <span className="Hamburger-line"></span>
                                    </span>
                                </span>
                            </button>
                            <div className="Nav-inner" data-component="NavMobile" id="mobile-nav">
                                <h2 className="u-noScreen" id="nav-main">Hlavní navigace</h2>
                                <div className="Nav-block Nav-block--main" data-ref="main" data-component="NavLine">
                                    <div className="Nav-header">
                                        <div className="Header-tools">
                                            <a href="https://www.cebianet.cz" className="Header-link" aria-label="Přihlášení"><span className="Icon Icon--user"></span></a>
                                        </div>
                                    </div>
                                    <div className="Nav-content" aria-label="Hlavní navigace" data-ref="list" id="nav-content">
                                        <a onMouseEnter={(e) => setIsFocused(e, true)} onClick={(e) => e.preventDefault()}
                                            onMouseLeave={(e) => setIsFocused(e, false)} data-rel="services" data-ref="item" href="https://cebia.cz/sluzby"
                                            className="Nav-link Nav-link--hasSubnav">Služby <span className="Nav-button" data-ref="toggle" data-rel="services" onClick={(e) => openSubmenu(e)}></span></a>
                                        <a data-ref="item" href="https://cebia.cz/autorizovana-pracoviste" className="Nav-link">Autorizovaná <br className="xl:u-hidden mdMax:u-hidden" />pracoviště</a>
                                        <a data-ref="item" href="https://cebia.cz/pruvodce" className="Nav-link">Jak koupit/prodat <br className="xl:u-hidden mdMax:u-hidden" />auto?</a>
                                        <a onMouseEnter={(e) => setIsFocused(e, true)}
                                            onMouseLeave={(e) => setIsFocused(e, false)}
                                            data-rel="about" data-ref="item" href="https://cebia.cz/o-nas" className="Nav-link Nav-link--hasSubnav">O nás <span onClick={(e) => openSubmenu(e)} className="Nav-button" data-ref="toggle" data-rel="about"></span></a>
                                        <a data-ref="item" href="https://cebia.cz/kontakty" className="Nav-link">Kontakty</a>
                                    </div>
                                    <div className="Nav-line" data-ref="NavLine:line"></div>
                                </div>
                                <div onMouseEnter={(e) => setIsFocused(e, true)}
                                    onMouseLeave={(e) => setIsFocused(e, false)}
                                    onClick={(e) => openSubmenu(e)}
                                    className={"Nav-block Nav-block--secondary " + getClass("services")} data-ref="block" data-rel="services" data-type="full">
                                    <div className="Nav-arrow" data-ref="arrow"></div>
                                    <div className="Nav-header" data-ref="toggle">
                                        <h3 className="Nav-title">Služby</h3>
                                    </div>
                                    <div className="Nav-layer" data-ref="layer" data-rel="services">
                                        <div className="Nav-column Nav-column--check">
                                            <h3 className="Nav-subtitle"><b>Prověření</b> vozidel</h3>
                                            <ul>
                                                <li><a href="https://cz.cebia.com"><b>Online prověření historie</b> AUTOTRACER</a></li>
                                                <li><a href="https://cebia.cz/sluzby/provin"><b>Prověření původu v zahraničí</b> PROVIN</a></li>
                                                <li><a href="https://cebia.cz/sluzby/vintest"><b>Fyzická kontrola VIN a identifikátorů</b> VINTEST</a></li>
                                            </ul>
                                        </div>
                                        <div className="Nav-column Nav-column--safety">
                                            <h3 className="Nav-subtitle"><b>Zabezpečení</b> vozidel</h3>
                                            <ul>
                                                <li><a href="https://cebia.cz/sluzby/piskovani-skel"><b>Pískování skel</b> OCIS</a></li>
                                                <li><a href="https://cebia.cz/sluzby/leptani-skel"><b>Leptání skel</b> OCIS</a></li>
                                                <li><a href="https://cebia.cz/sluzby/cebia-sat"><b>Satelitní zabezpečení</b></a></li>
                                                <li><a href="https://cebia.cz/uniqa-safeline"><b>UNIQA SafeLine</b></a></li>
                                                <li><a href="https://cebia.cz/allianz-bonusdrive"><b>Allianz BonusDrive</b></a></li>
                                            </ul>
                                        </div>
                                        <div className="Nav-column Nav-column--other">
                                            <h3 className="Nav-subtitle"><b>Další</b> služby</h3>
                                            <ul>
                                                <li><a href="https://cebia.cz/sluzby/vinfoto"><b>Prohlídka k pojištění vozidla</b> VINFOTO</a></li>
                                                <li><a href="https://exekuce.cebia.cz"><b>Kontrola exekucí</b></a></li>
                                                <li><a href="https://www.carolina.cz"><b>Mobilní aplikace</b> Carolina</a></li>
                                                <li><a href="https://www.cebianet.cz"><b>Pro partnery</b></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div onMouseEnter={(e) => setIsFocused(e, true)}
                                    onMouseLeave={(e) => setIsFocused(e, false)}
                                    onClick={(e) => openSubmenu(e)}
                                    className={"Nav-block Nav-block--float Nav-block--secondary " + getClass("about")}
                                    data-ref="block" data-rel="about" data-type="float" style={currState.navOpened ? { left: '0' } : { left: '594.266px' }}>
                                    <div className="Nav-arrow" data-ref="arrow"></div>
                                    <div className="Nav-header" data-ref="toggle">
                                        <h3 className="Nav-title">O nás</h3>
                                    </div>
                                    <div className="Nav-layer Nav-layer--about" data-rel="about">
                                        <ul>
                                            <li><a href="https://cebia.cz/novinky" className="Nav-iconLink"><span className="Icon Icon--sheets" aria-hidden="true"></span> Novinky</a></li>
                                            <li><a className="Nav-iconLink" href="https://cebia.cz/o-nas#historie"><span className="Icon Icon--hourglass" aria-hidden="true"></span> Historie společnosti</a></li>
                                            <li><a className="Nav-iconLink" href="https://cebia.cz/o-nas#partneri"><span className="Icon Icon--note" aria-hidden="true"></span> Naši partneři</a></li>
                                            <li><a className="Nav-iconLink" href="https://cebia.cz/o-nas#media"><span className="Icon Icon--megaphone" aria-hidden="true"></span> Pro média</a></li>
                                            <li><a className="Nav-iconLink" href="https://cebia.cz/o-nas#volne-pozice"><span className="Icon Icon--badge" aria-hidden="true"></span> Kariéra</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="Header-tools">
                            {userIdentity.isAuthenticated && <Link to={"/admin/logout"} className="Header-link isAuthenticated" >
                                <ul>
                                    <li style={{ textAlign :  "center" }}><span className="Icon Icon--badge"></span></li>
                                <li>{userIdentity.firstName} {userIdentity.lastName}</li>
                                </ul>
                            </Link>}
                            {/* {!userIdentity.isAuthenticated && <Link to={"/admin/"} className="Header-link isNonAuthenticated" ><span className="Icon Icon--badge"></span></Link>} */}
                        </div>
                        <button type="button" className={'NavToggle NavToggle--static' + (currState.navOpened ? " is-active" : "")} data-component="NavOpener" data-options='{"static": true}' onClick={() => handleClick()}>
                            <span className="NavToggle-label">Menu</span>
                            <span className="Hamburger">
                                <span className="Hamburger-content">
                                    <span className="Hamburger-line"></span>
                                    <span className="Hamburger-line"></span>
                                    <span className="Hamburger-line"></span>
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

