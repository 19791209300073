import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { getResult } from '../../BL/orderBL'
import { ExekuceData } from '../../models/exekuceModel'
import ExekuceSubject from '../../components/order/orderResultSubject'
import ExekuceItems from '../../components/order/orderResultItem'
import ExekuceDetailItems from '../../components/order/orderDetailResultItem'
import { useDispatch } from 'react-redux';
import PaymentBanner from '../../components/order/paymentBanner';
import { Link } from 'react-router-dom';
import Faq from '../../components/home/faq';
import ModalQuestion from '../../components/_base/modalQuestion';
import ChangeTitleDescription from '../../components/_base/titleDescChanger';

export default function OrderResult() {
    const { id } = useParams<{ id: string }>();
    const [items, setItems] = useState<ExekuceData>(new ExekuceData());
    const dispatch = useDispatch();
    const printRef = useRef(null);
    const history = useHistory();
    let rowNumber = 1;

    useEffect(() => {
        getResult(id, setItems, dispatch);

        ChangeTitleDescription("Výsledek kontroly exekucí | Cebia.cz", "Okamžitá kontrola exekucí fyzických i právnických osob v Centrální evidenci exekucí ČR. Informace o počtu vedených exekucí i výše dlužných částek.");
     }, [id]);


    const totalRows = (items.data?.length ?? 0);// (items.data?.length ?? 0) + (items.detailData?.length ?? 0);

    function kolko(pocet: number) {
        switch (totalRows) {
            case 1:
                return `${totalRows} záznam`;
            case 2:
            case 3:
            case 4:
                return `${totalRows} záznamy`;
            default:
                return `${totalRows} záznamů`;
        }
    }


    function errorAllState() {
        if ((items?.data?.length ?? 0) === 0 && items.status === 200) return false;
        return ((items?.data?.every(x => x.error_status !== 0)) ?? false) || items.status !== 200;
    }

    function errorSomeState() {
        if ((items?.data?.length ?? 0) === 0) return false;
        return items?.data?.some(x => x.error_status !== 0) ?? false;
    }

    return (
        <>
            <section className="Section Section--bare">
                <div className="Container">
                    <div className="exekuce-PageHeader">
                        <h1 className="exekuce-PageHeader-title Beta">{items.expired ? "Platnost výpisu vypršela" : "Výpis z Centrální evidence exekucí"}</h1>
                    </div>
                </div>
            </section>
            {
                items.expired &&
                <>
                    <section className="Section Section--appended">
                        <div className="Container">
                            <form className="Form">
                                <div className="Section Section--slim">
                                    <div className="Container">
                                        <div className="lg:mb-50 lg:mt-50 text-center">
                                            <p>Výsledek kontroly exekucí již nelze zobrazit z důvodu uplynutí lhůty 24 hodin, kdy lze po nákupu kontroly k informacím přistupovat. Novou kontrolu zahájíte kliknutím na tlačítko níže.</p>
                                        </div>
                                        <div className="Section Section--appended pb-50">
                                            <div className="Separator"></div>
                                        </div>
                                        <div className="Grid-cell smw:w-auto Flex Flex--justifyCenter">
                                            <div className="Grid-cell smw:w-auto">
                                                <button className="Button" onClick={() => history.push("/")}>
                                                    <span className="Button-content">Nová kontrola</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </>
            }
            {!items.expired &&
                <>
                    <ExekuceSubject subject={items} />

                    {errorAllState() &&
                        <>
                            <section className="Section Section--appended pb-30">
                                <div className="Container">
                                    <div className="Section Section--slim pt-0">
                                        <div className="Container">
                                            <div className="exekuce-InfoCount justifyCenter noBorder pb-0">
                                                <div className="exekuce-InfoCount-note">
                                                    <span className="VisualIcon VisualIcon--circleWarning"></span>
                                                    <h2 className="color-red">Omlouváme se, výsledek kontroly se nepodařilo načíst.</h2>
                                                </div>
                                            </div>
                                            <div className="lg:mb-20 lg:mt-20 text-center">
                                                <p>Co s tím? Většinou pomůže obnovení stránky, pokud ne, pak se jedná o výpadek systému, který je zpravidla vyřešen do 60 minut. Mějte s námi prosím strpení a zkuste stránku obnovit za chvíli. Odkaz na tuto stránku jsme vám poslali také na e-mail.</p>
                                            </div>
                                            <div className="Grid-cell smw:w-auto Flex Flex--justifyCenter pb-30">
                                                <div className="Grid-cell smw:w-auto">
                                                    <button className="Button" onClick={() => history.go(0)}>
                                                        <span className="Button-content">Obnovit stránku</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="Section Section--appended pb-0">
                                                <div className="Separator"></div>
                                            </div>
                                            <div className="lg:mb-20 lg:mt-20 text-center">
                                                <p className="text-center">Pokud máte jakýkoliv dotaz, rádi vám na něj odpovíme na adrese <a href="mailto:exekuce@cebia.cz" >exekuce@cebia.cz.</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    {errorSomeState() &&
                        <>
                            <section className="Section Section--appended pb-30">
                                <div className="Container">
                                    <div className="Section Section--slim pt-0">
                                        <div className="Container">
                                            <div className="exekuce-InfoCount justifyCenter noBorder pb-0">
                                                <div className="exekuce-InfoCount-note">
                                                    <span className="VisualIcon VisualIcon--circleWarning"></span>
                                                    <h2 className="color-red">Kontrola nebyla dokončena!</h2>
                                                </div>
                                            </div>
                                            <div className="lg:mb-20 lg:mt-20 text-center">
                                                <p>Omlouváme se, nepodařilo se načíst všechna data. Mějte s námi prosím strpení a zkuste stránku po chvíli obnovit. Pokud tento text po obnovení stránky zmizí, kontrola se úspěšně dokončila.</p>
                                            </div>
                                            <div className="lg:mb-10 lg:mt-20 text-center">
                                                <ModalQuestion
                                                    link="Více informací"
                                                    header="Co znamená, že kontrola nebyla dokončena?"
                                                    text="Pokud nebyla kontrola dokončena, zřejmě jsou data o některých exekucích ještě na cestě, nebo se vyskytl dočasný technický problém. Zkoušejte prosím průběžně obnovovat webovou stránku, ve většině případů jsou problémy vyřešeny do 60 minut. Po úspěšném dokončení kontroly se může zvýšit počet nalezených exekucí."
                                                    text2="Pokud máte jakýkoliv dotaz, rádi vám na něj odpovíme na adrese <a href='mailto:exekuce@cebia.cz'>exekuce@cebia.cz.</a>" />
                                            </div>
                                            <div className="Grid-cell smw:w-auto Flex Flex--justifyCenter pb-30">
                                                <div className="Grid-cell smw:w-auto">
                                                    <button className="Button" onClick={() => history.go(0)}>
                                                        <span className="Button-content">Obnovit stránku</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="Section Section--appended pb-0">
                                                <div className="Separator"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    {!errorAllState() &&
                        <section className="Section Section--fat Section--appended">
                            <div className="Container pb-100">
                                <div className="exekuce-InfoCount">
                                    <h2 className="exekuce-InfoCount-title">
                                        Celkem nalezeno: <span className={`${totalRows > 0 ? "color-red" : "color-green"}`}>{kolko(totalRows)}</span>
                                    </h2>

                                    <div className="exekuce-InfoCount-note md:w-5/12 lg:w-4/12">
                                        {totalRows === 0 &&
                                            <>
                                                <span className="VisualIcon VisualIcon--circleCheck"></span>
                                                <p>
                                                    V Centrální evidenci exekucí ČR nebyl na základě zadaných údajů nalezen <strong className="color-green">žádný záznam.</strong>
                                                </p>
                                            </>
                                        }
                                        {totalRows > 0 &&
                                            <>
                                                <span className="VisualIcon VisualIcon--circleWarning"></span>
                                                <p>
                                                    {
                                                        totalRows > 1 &&
                                                        <>
                                                            V Centrální evidenci exekucí ČR byly na základě zadaných údajů nalezeny <strong className="color-red">záznamy o exekucích.</strong>
                                                        </>
                                                    }
                                                    {
                                                        totalRows === 1 &&
                                                        <>
                                                            V Centrální evidenci exekucí ČR byl na základě zadaných údajů nalezen <strong className="color-red">záznam o exekuci.</strong>
                                                        </>
                                                    }
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="Section Section--medium">
                                    <div className="exekuce-CardList">
                                        {
                                            items.data?.map((item, index) => {
                                                // Kdyz na exekuci uz existuje detail tak ji nezobrazuju ale se zobrazi detail v jine komponente
                                                if (!items.detailData || items.detailData.length === 0 || items.detailData.find(x => x.cause_id === item.cause_id) == undefined) {
                                                    return <ExekuceItems key={"exekItem" + index} exekuce={item} index={rowNumber++} total={totalRows} />
                                                }
                                                else {
                                                    return <ExekuceDetailItems key={"exekDetItem" + rowNumber + 1}
                                                        exekuceDetail={items.detailData.find(x => x.cause_id === item.cause_id)!}
                                                        index={rowNumber++} total={totalRows} />
                                                }
                                            })
                                        }
                                        {/* {
                                items.detailData?.map((item, index) => {
                                    return <ExekuceDetailItems key={"exekDetItem" + index} exekuceDetail={item} index={rowNumber++} total={totalRows} />
                                })
                            } */}
                                    </div>
                                </div>

                                <div className="Flex Flex--justifyCenter pt-30" >
                                    <Link ref={printRef} to={`/Exekuce/GetExekuceDetailPdf?id=` + id} className="FileLink" target="_blank">Uložit výpis jako PDF</Link>
                                </div>
                                <p className="text-center">Doporučujeme si výpis ihned uložit, výsledky jsou dostupné <strong>jen po dobu 24 hodin.</strong></p>
                            </div>
                            {((items.data?.length ?? 0) > 0 && (items.data?.length !== items.detailData?.length)) &&
                                <div className="Container Container--medium">
                                    <PaymentBanner refPos={printRef} totalCount={items.data?.filter(x => x.is_payed === false)?.length ?? 0} exekuceItems={items.data} />
                                </div>
                            }
                        </section>
                    }
                </>
            }
            <Faq />
        </>
    );
}
