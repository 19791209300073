import React, { createContext, useContext, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import Order from "./pages/order/Order";
import ScrollToTop from "./components/_base/scrollToTop";
import ShowGrid from "./pages/administ/showItems";
import { isAuthenticated } from "./BL/features/userSlice";
import { useSelector } from "react-redux";
import AuthPage, { AuthPageSignOut } from "./pages/administ/authPage";
import TagManager from "react-gtm-module";
import { NavigationProvider } from "./hooks/useNavigation";

const tagManagerArgs = {
  gtmId: "GTM-WHJDD82",
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  const isAuth = useSelector(isAuthenticated);
  const { pathname, state } = useLocation();

  useEffect(() => {
    let classPage =
      pathname.toLowerCase().indexOf("rc") > 0 ||
      pathname.toLowerCase().indexOf("ic") > 0 ||
      pathname === "/"
        ? "page-Homepage"
        : "Page";

    let el = document.getElementById("exekuceContainer");
    el?.removeAttribute("class");
    el?.setAttribute("class", classPage);
  }, [pathname, state]);

  return (
    <>
      <NavigationProvider>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/rc" component={Home} />
          <Route exact path="/ic/:ic?" component={Home} />

          <Route path="/order" component={Order} />
          <Route exact path="/admin" component={AuthPage} />

          {isAuth && <Route path="/showItems" component={ShowGrid} />}
          {/* <Route path="/showItems" component={ShowGrid} /> */}
          {isAuth && (
            <Route exact path="/admin/logout" component={AuthPageSignOut} />
          )}

          <Route path="*">
            <Redirect to="/" push />
          </Route>
        </Switch>
      </NavigationProvider>
    </>
  );
}
