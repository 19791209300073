import React from 'react';

export enum MessageType {
    Info = 0,
    Error = 1,
}


interface Props {
    content: string;
    isVisible: boolean;
    type?: MessageType
}

export default function ShowMessage({ content, isVisible = false, type = MessageType.Error }: Props) {
    return (
        <> {isVisible &&
            <section className="Section Section--appended Section--medium">
                <div className="Container">
                    {isVisible && type === MessageType.Error &&
                        <div className="Form-headline lg:mb-40 Info Info--red pt-15 pb-15">
                            <span className="Flex Flex--alignCenter Flex--justifyCenter Icon Icon--alert pl-10 pr-10">{content}</span>
                        </div>
                    }
                    {isVisible && type === MessageType.Info &&
                        <div className="Form-headline lg:mb-40 Info Info--green pt-15 pb-15">
                            <span className="Flex Flex--alignCenter Flex--justifyCenter Icon Icon--circleInfo pl-10 pr-10">{content}</span>
                        </div>
                    }
                </div>
            </section>
        }
        </>
    )
}