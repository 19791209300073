import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import MainMenu from '../../components/_base/mainMenu';
import { hideLoader } from '../../BL/features/loaderSlice';
import { useTable, useGlobalFilter, useFilters, Row } from 'react-table'
import { GlobalHeadFilter, headDefCol } from '../../components/administ/orderGridTable'
import { getOrderDatas } from '../../BL/adminBL';
import { orderGridItem } from '../../models/administModel';


export default function ShowGrid() {
  const dispatch = useDispatch();
  const [data, setCurrentTableData] = useState(new Array<orderGridItem>());
  //const data = React.useMemo(() => currentData, []);
  const columns = React.useMemo(headDefCol, []);
  const [loadData, setLoadData] = useState(true);

  function ConvertToDate(itemDate: Date): string {

    itemDate = new Date(itemDate);
    let result = itemDate.getDate().toString().padStart(2, '0') + "." + (itemDate.getMonth() + 1).toString().padStart(2, '0') + "." + itemDate.getFullYear() + " " + itemDate.getHours().toString().padStart(2, '0') + ":" + itemDate.getMinutes().toString().padStart(2, '0');

    return result;
  }

  function filterDef(rows: Row<orderGridItem>[], columnIds: string[], filterValue: any): Row<orderGridItem>[] {
    return rows.filter((row: Row<orderGridItem>) => {

      let result: boolean = false;

      columnIds.map((item, index) => {
        let rowValue = row.values[item];

        if (item !== "viewId") {

          if (item === "created" || item === "bornDate") {
            rowValue = ConvertToDate(rowValue);
          }

          result ||= (rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true);
        }

      });

      return result;
    });
  }

  const filterType = React.useMemo(
    () => filterDef, [])

  useEffect(() => {
    console.log(loadData);
    
    if (loadData) {
      getOrderDatas(setCurrentTableData);
      dispatch(hideLoader());
      setLoadData(false);
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows
  } = useTable({
    columns, data, initialState: {
      // hiddenColumns:["viewId"]
    },
    globalFilter: filterType,
  }, useFilters, useGlobalFilter)


  return (<>
    <div id="homePageHeaderContainer">
      <MainMenu />
      <div className="Blob">
        <div className="Container Container--full">
          <div className="Blob-content">
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="Section Section--bare">
        <div className="Container Container--full">
          <table {...getTableProps()} className="dispatchingTable">
            <thead>
              <tr>
                <th colSpan={16} style={{ textAlign: 'left' }}>
                  <GlobalHeadFilter preGlobalFilteredRows={0} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </th>
              </tr>
              {
                headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps({ style: { width: column.width } })}>
                          {
                            column.render('Header')
                          }
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {
                        row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps({ style: { width: cell.column.width } })}>
                              {
                                cell.render('Cell')
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>);
}