import React from "react"
import { Link } from "react-router-dom"
import { SectionFindType, SearchOptions } from "../../models/orderModel";
import NapovedaModal from './napovedaModal'

interface Props {
    secType: SectionFindType;
}

export default function MainNavSection(varSecType: Props) {
    const baseClassName: string = "homepage-FormTab-label";

    return (
        <>
            <div className="Grid gmb-5 Grid--alignMiddle">
                <div className="Grid-cell Flex Flex--justifyCenter Flex--alignCenter md:w-3/12">            
                    <NapovedaModal secType={varSecType.secType} />                           
                </div>
                <div className="Grid-cell md:w-9/12">
                        <div className="homepage-Form-tabs smwMax:u-hidden" data-ref="tabs">
                            {
                                SearchOptions.map((option, index) => (                     
                                    <Link key={index} to={option.url} className="homepage-FormTab" >
                                        <span className={baseClassName + (varSecType.secType == index  ? " activeHomeLabel" : "")}><span className="smwMax:u-hidden">Podle </span>{option.label}</span>
                                    </Link>
                                )) 
                            }                        
                        </div>
                    </div>
            </div>
        </>
    )
}