import { createSlice } from '@reduxjs/toolkit';
import UserIdentity from '../../models/userModel';


export const slice = createSlice({
    name: 'user',
    initialState: {
        value: new UserIdentity(),
    },
    reducers: {
        setUserIdentity: (state, action) => {
            Object.assign(state.value, action.payload);
        }
    },
});

export const { setUserIdentity } = slice.actions;

export const getUserIdentity = (state: { user: { value: UserIdentity; }; }) => state.user.value;

export const isAuthenticated = (state: { user: { value: UserIdentity; }; }) => (state.user.value && state.user.value.isAuthenticated);

export default slice.reducer;