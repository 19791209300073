import React, { Component } from "react";
import { Submenu } from "../../components/home/submenu";
import { Benefits } from "../../components/home/benefits";
import { Howto } from "../../components/home/howto";
import { Warning } from "../../components/home/warning";
import { Price } from "../../components/home/price";
import Example from "../../components/home/example";
import { WhyDoIt } from "../../components/home/whydoit";
import { Faq } from "../../components/home/faq";
import { Contact } from "../../components/home/contact";
import { CebiaServices } from "../../components/home/cebiaservices";
import CreateOrderForm from "../../components/home/startOrderForm";
import MainMenu from "../../components/_base/mainMenu";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../BL/features/loaderSlice";
import Alert from "../../components/home/alert";

export default function Home() {
  const dispatch = useDispatch();
  dispatch(hideLoader());

  return (
    <div>
      <div id="homePageHeaderContainer">
        <MainMenu />
        <div className="Blob">
          <div className="Container Container--full">
            <div className="Blob-content"></div>
          </div>
        </div>
      </div>
      <div className="homepage-PageHeader" id="section-slider">
        <div className="Container">
          <div className="Shape Shape--darkBlueGradient"></div>
          <div className="Shape Shape--blue"></div>
          <div className="Shape Shape--green"></div>
          <div className="Shape Shape--greenLine"></div>
          <div className="Shape Shape--blueLine"></div>

          <div className="homepage-PageHeader-header">
            <h1 className="homepage-PageHeader-title Alfa">Kontrola exekucí</h1>
            <p>Vyhledat záznam v Centrální evidenci exekucí ČR</p>
          </div>
          {/* <Alert /> */}
          <div className="Container Container--narrow is-pure smw:pr-20 smw:pl-20 xl:pr-50 xl:pl-50">
            <CreateOrderForm />
          </div>
        </div>
      </div>
      <Submenu />
      <Benefits />
      <Howto />
      <Warning />
      <Price />
      <Example />
      <WhyDoIt />
      <Faq />
      <Contact />
      <CebiaServices />
      <article className="CookieBubble" data-component="CookieBubble">
        <div className="CookieBubble-content">
          <p>
            Tento web používá k poskytování služeb, personalizaci reklam a
            analýze návštevnosti soubory cookie. Používáním tohoto webu
            vyjadřujete souhlas s tímto nastavením.{" "}
            <a href="#todo">Více informací</a>.
          </p>
          <button
            type="button"
            className="Button Button--icon"
            data-ref="cookieButton"
            aria-label="Zavřít"
          >
            <span className="Icon Icon--crossTiny"></span>
          </button>
        </div>
      </article>
    </div>
  );
}
