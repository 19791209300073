import React, { Component } from 'react'
import Accordion, { AccordionBody, AccordionHead, AccordionItem } from '../_base/Accordion/accordion';


export class Faq extends Component {
    render() {
        return (
            <section className="Section Section--appended" id="section-faq">
                <div className="Container Container--wide">
                    <div className="Section-shape Section-shape--leftBottom"></div>
                </div>
                <div className="Container Container--slim">
                    <div className="homepage-Faq">
                        <div className="homepage-Faq-header">
                            <h2 className="Beta">FAQ</h2>
                            <p>Otázky které nám položili zákazníci před vámi</p>
                        </div>
                        <div className="Grid gmb-15" data-component="Accordion">
                            <Accordion id="t1">
                                <AccordionItem  >
                                    <AccordionHead  >
                                        <h3>Je kontrola exekucí aktuální?</h3>
                                    </AccordionHead>
                                    <AccordionBody  >
                                        <p>Ano. Kontrola je aktuální k času jejího vygenerování, pro zobrazení změn provedených v Centrální evidenci exekucí po zakoupení kontroly je nutné zakoupit kontrolu znovu.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem  >
                                    <AccordionHead  >
                                        <h3>Jaké údaje v Centrální evidenci exekucí nenaleznu?</h3>
                                    </AccordionHead>
                                    <AccordionBody  >
                                        <p>Centrální evidence exekucí neobsahuje např. exekuce vedené orgány státní správy a samosprávy, finančními orgány či zdravotními pojišťovnami.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem  >
                                    <AccordionHead  >
                                        <h3>Na jakou částku je exekuce vedena?</h3>
                                    </AccordionHead>
                                    <AccordionBody  >
                                        <p>Výše částky je uvedena v detailu exekuce ke dni zápisu spisu do Centrální evidence exekucí. Jste-li účastníkem exekuce, získáte přesnou a aktuální částku ke dni a další náležitosti spisu dotazem na exekutorský úřad, který exekuci vede. Seznam soudních exekutorů naleznete <a href="https://www.ekcr.cz/seznam-exekutoru" target="_blank">zde</a>.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem  >
                                    <AccordionHead   >
                                        <h3>Co dělat, když jsou informace na výpisu nesprávné?</h3>
                                    </AccordionHead>
                                    <AccordionBody   >
                                        <p>Jsou-li uvedené informace podle vašeho názoru nesprávné, kontaktujte soudního exekutora, který byl pověřen vedením daného exekučního řízení. Cebia nemůže jakkoliv zasahovat do exekučního řízení či pozměňovat údaje uvedené v Centrální evidenci exekucí. Seznam soudních exekutorů naleznete <a href="https://www.ekcr.cz/seznam-exekutoru" target="_blank">zde</a>.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem >
                                    <AccordionHead >
                                        <h3>Proč je kontrola zpoplatněna?</h3>
                                    </AccordionHead>
                                    <AccordionBody  >
                                        <p>Nahlížení do Centrální evidence exekucí ČR je dle vyhlášky č. 329/2008 Sb., o centrální evidenci exekucí zpoplatněno, a to za každý pokus o poskytnutí elektronického údaje z centrální evidence exekucí. Tedy za zobrazení podrobností konkrétní exekuce i za výpis zda daný subjekt má, či nemá exekuci.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem   >
                                    <AccordionHead  >
                                        <h3>Kdy jsou záznamy v evidence mazány?</h3>
                                    </AccordionHead>
                                    <AccordionBody  >
                                        <p>Záznam o exekuci je vymazán 15 dní poté, co bylo exekuční řízení zcela skončeno (resp. poté, co se o skončení řízení dozvěděl soudní exekutor). Dotazy k probíhajícím exekučním řízením vedených na vaši osobu, kontaktujte příslušného soudního exekutora. Jejich seznam naleznete <a href="https://www.ekcr.cz/seznam-exekutoru" target="_blank">zde</a>.</p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;
