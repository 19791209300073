import React, { useEffect, useState } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom';
import SubjectRc from '../../components/order/orderSubjectRc'
import subjIc from '../../components/order/orderSubjectIc'
import subjName from '../../components/order/orderSubjectName'
import FindSubmitButton from '../_base/findSubmitControl'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { OrderValues, SectionFindType } from '../../models/orderModel';
import { selectOrderValue, setFindData } from '../../BL/features/orderSlice'
import { ExekuceTicketPrice } from '../../models/orderModel';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToHome } from '../../BL/orderBL';
import { RcValidator } from '../../BL/validator'
import ModalQuestion from '../_base/modalQuestion';
import ChangeTitleDescription from '../_base/titleDescChanger';

export default function OrderAddOrder() {
    const history = useHistory();
    const oldVal = useSelector(selectOrderValue);
    const dispatch = useDispatch();
    const [totalPrice, setTotalPrice] = useState(ExekuceTicketPrice);

    const { pathname, state } = useLocation();
    const [linkQuestion, setQuestion] = useState({ linkName: "", headerText: "", text: "Exekuční řízení fyzických osob jsou v Centrální evidenci exekucí vedeny pod rodným číslem subjektu nebo podle jeho jména, příjmení a data narození. U některých exekučních řízeních je však vyplněno pouze rodné číslo nebo pouze jméno, příjmení a datum narození.<br /><strong>Doporučujeme proto zkontrolovat exekuce oběma způsoby.</strong>" });

    useEffect(() => {
        if ((pathname.toLowerCase() === "/order/s1/")) {
            setQuestion({ ...linkQuestion, linkName: "Proč kontrolovat exekuce i podle jména a příjmení?", headerText: "Proč kontrolovat exekuce i podle jména a příjmení?" });
        }
        else if ((pathname.toLowerCase() === "/order/s1/name/")) {
            setQuestion({ ...linkQuestion, linkName: "Proč kontrolovat exekuce i podle rodného čísla?", headerText: "Proč zkontrolovat exekuce i podle rodného čísla?" });
        }

        ChangeTitleDescription("Objednávka kontroly exekucí | Cebia.cz", "Okamžitá kontrola exekucí fyzických i právnických osob v Centrální evidenci exekucí ČR. Informace o počtu vedených exekucí i výše dlužných částek.");
    }, [pathname, state]);

    redirectToHome(oldVal.id, undefined, history);

    function createValidationSchema() {
        Yup.addMethod(Yup.date, 'dateValidator', function yupDate(msg) {
            return this.test('dateValTest', msg, function (value) {
                const isRcPath = !window.location.pathname.toLowerCase().includes('/order/s1/name') && !window.location.pathname.toLowerCase().includes('/order/s1/ic');
                let isValid = (value && value.toDateString().length >= 10 ? true : false);

                return !isRcPath || isValid;
            })
        });

        Yup.addMethod(Yup.string, 'bornNumValidator', function yupRc(msg) {
            return this.test('bronValTest', msg, function (value) {
                const isRcPath = window.location.pathname.toLowerCase().includes('/order/s1/name');
                const isValid = RcValidator(value);

                return !isRcPath || isValid;
            })
        });

        return Yup.object().shape({
            firstName: Yup.string().max(64, 'Maximální počet znaků je 64.').when(['lastName', 'bornDate'], {
                is: (l: any, b: Date) => (l && l.length > 0) || (b && b.toDateString().length > 0),
                then: Yup.string().required('Prosím zadejte položku Jméno')
            }),
            lastName: Yup.string().max(64, 'Maximální počet znaků je 64.').when(['firstName', 'bornDate'], {
                is: (f: any, b: Date) => (f && f.length > 0) || (b && b.toDateString().length > 0),
                then: Yup.string().required('Prosím zadejte položku Příjmení')
            }),
            bornDate: Yup.date().nullable().when(['firstName', 'lastName'], {
                is: (f: any, l: any) => (f && f.length > 0) || (l && l.length > 0),
                then: Yup.date().dateValidator('Nesprávna hodnota Datum narození.')
            }),
            rc: Yup.string().max(12).bornNumValidator('Nesprávna hodnota RČ.')
        }, [['firstName', 'lastName'], ['firstName', 'bornDate'], ['lastName', 'bornDate'], ['rc', '']]);
    }

    function handleSubmit(values: OrderValues) {
        values.price = totalPrice;
        dispatch(setFindData(values));
        history.push('/order/s2');
    }

    function AutoSubmitToken() {
        const { values } = useFormikContext<OrderValues>();

        React.useEffect(() => {
            let addItemPrice: boolean = false;

            switch (values.initType) {
                case SectionFindType.Name:
                    addItemPrice = values.rc.trim().length > 0;
                    break;
                case SectionFindType.Rc:
                    addItemPrice = values.firstName.length > 0 || values.lastName.length > 0 || (values.bornDate !== undefined && values.bornDate.toString().length > 0);
                    break;
                case SectionFindType.Ic:
                    addItemPrice = false;
                    break;
            }

            setTotalPrice(ExekuceTicketPrice * (addItemPrice ? 2 : 1));

        }, [values]);

        return null;
    };

    return (
        <>

            <section className="Section Section--bare">
                <div className="Container">
                    <div className="exekuce-PageHeader">
                        <h1 className="exekuce-PageHeader-title Beta">Objednávka kontroly exekucí</h1>
                    </div>
                </div>
            </section>


            <section className="Section Section--appended">
                <div className="Container">
                    <Formik initialValues={oldVal} validationSchema={createValidationSchema} onSubmit={values => { handleSubmit(values); }}>
                        <Form className="Form">

                            <Route exact path="/order/s1/" component={SubjectRc} />
                            <Route exact path="/order/s1/ic/" component={subjIc} />
                            <Route exact path="/order/s1/name/" component={subjName} />

                            <div className="Section Section--slim">
                                <div className="Container">
                                    <div className="Grid Flex gap-30 gmb-10">
                                        {linkQuestion.linkName &&
                                            <div className="Grid-cell smw:w-auto align-self-center">
                                                <ModalQuestion link={linkQuestion.linkName} header={linkQuestion.headerText} text={linkQuestion.text} />
                                            </div>
                                        }
                                        <div className="Grid-cell smw:w-auto Flex Flex--alignMiddle Flex--justifyCenter ml-auto detailPrice">
                                            <div className="Price Price--small"><span className="Price-final">{totalPrice} Kč</span></div>
                                            <div className="Grid-cell smw:w-auto">
                                                <FindSubmitButton label="Pokračovat" classes="Button--full" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AutoSubmitToken />
                        </Form>
                    </Formik>
                </div>
            </section>
        </>
    );
}

