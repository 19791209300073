import { createSlice } from '@reduxjs/toolkit';


export const slice = createSlice({
    name: 'loader',
    initialState: {
        value: true,
    },
    reducers: {
        showLoader: (state) => {
            state.value = true;
        },
        hideLoader: (state) => {
            state.value = false;
        },
    },
});

export const { showLoader, hideLoader } = slice.actions;

export const loaderValue = (state: { loader: { value: boolean; }; }) => state.loader.value;


export default slice.reducer;