import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink ,useLocation  } from 'react-router-dom'
import Logo from '../../assets/images/logo-cebia.svg';
import { selectOrderValue } from '../../BL/features/orderSlice';
import { SectionFindType } from '../../models/orderModel';

export default function Step() {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 120);
        });
    }, []);

    const oldVal = useSelector(selectOrderValue);

    let destUrl = "";
    if (oldVal.initType === SectionFindType.Ic) {
        destUrl += "Ic/"
    }
    else if (oldVal.initType === SectionFindType.Name) {
        destUrl += "Name/"
    }
      
    return (
        <>
            <div className={"exekuce-OrderStep " + (scroll ? "is-sticky-visible" : "")} data-component="BannerAlt">
                <div className="Container Container--medium">
                    <div className="exekuce-OrderStep-logo">
                        <a href="/">
                            <img src={Logo} width="130" height="41" alt="cebia" />
                        </a>
                    </div>

                    <div className="exekuce-OrderStep-list">
                        { location.pathname.indexOf('order/detail/') === -1 && <NavLink strict to={'/order/s1/' + destUrl} activeClassName="is-active" className="exekuce-OrderStep-item"><span className="exekuce-OrderStep-number hide-mobile">01</span><span className="exekuce-OrderStep-text hide-mobile">Prověřovaný subjekt</span><span className="exekuce-OrderStep-textAlt show-mobile">Subjekt</span></NavLink>}
                        { location.pathname.indexOf('order/detail/') > 0 && <NavLink strict to={'/order/detail/'} activeClassName="is-active" className="exekuce-OrderStep-item"><span className="exekuce-OrderStep-number hide-mobile">01</span><span className="exekuce-OrderStep-text hide-mobile">Souhrn objednávky</span><span className="exekuce-OrderStep-textAlt show-mobile">Dostupnost</span></NavLink> }
                        <NavLink to="/order/s2/" activeClassName="is-active" className="exekuce-OrderStep-item" ><span className="exekuce-OrderStep-number hide-mobile">02</span><span className="exekuce-OrderStep-text hide-mobile">Způsob platby</span><span className="exekuce-OrderStep-textAlt show-mobile">Platba</span></NavLink>
                        <NavLink to="/order/result/" activeClassName="is-active" className="exekuce-OrderStep-item" ><span className="exekuce-OrderStep-number hide-mobile">03</span><span className="exekuce-OrderStep-text hide-mobile">Zobrazení informací</span><span className="exekuce-OrderStep-textAlt show-mobile">Výsledek</span></NavLink>
                    </div>
                </div>
            </div>
            <div className="SectionShapeStep">
                <div className="Container">
                    <div className="Section-shape Section-shape--right"></div>
                </div>
            </div>
        </>
    )
}
