import React, { Component } from "react";
import ContactFormModal from "./contactFormModal";

export class Contact extends Component {
  render() {
    return (
      <article className="Section Section--appended">
        <div className="Container Container--slim text-center smw:text-left">
          <div className="Grid gap-40 md:gap-100 gmb-20 Grid--alignMiddle">
            <div className="Grid-cell smw:w-fill">
              <p className="GamaSize color-darkBlue">
                Nenašli jste odpověď na něco, co vás zajímá? Napište nám.
              </p>
            </div>
            <div className="Grid-cell smw:w-auto">
              <ContactFormModal />
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default Contact;
