import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../BL/features/loaderSlice';
import { selectDetailList } from '../../BL/features/detailSlice';
import { DetailTicketPrice } from '../../models/orderModel';
import { Link, useHistory, useParams } from 'react-router-dom';

export default function OrderDetail() {
    const urlId = useParams<{ id: string }>();
    const history = useHistory();
    const detailListVal = useSelector(selectDetailList);
    const dispatch = useDispatch();
    dispatch(hideLoader());

    if (detailListVal.value.length === 0) {
        history.push("/order/result/" + urlId.id);
    }

    return (
        <>
            <section className="Section Section--bare">
                <div className="Container">
                    <div className="exekuce-PageHeader">
                        <h1 className="exekuce-PageHeader-title Beta">Nákup detailu exekuce</h1>
                    </div>
                </div>
            </section>
            <section className="Section Section--appended">
                <div className="Container">
                    <div className="Form">
                        <div className="Section Section--slim pb-0">
                            <div className="Container">
                                <div className="exekuce-InfoCount">
                                    <h2 className="exekuce-InfoCount-title">Vybráno detailů: <span>{detailListVal.value.length}</span></h2>
                                    <div className="Grid Grid--alignRight gap-30 gmb-10">
                                        <div className="Grid-cell smw:w-auto Flex Flex--alignMiddle Flex--justifyCenter detailPrice">
                                            <div className="Price Price--small"><span className="Price-final">{detailListVal.value.length * DetailTicketPrice} Kč</span></div>
                                            <div className="Grid-cell smw:w-auto">
                                                <Link to={"/order/s2/" + urlId.id} className="Button Button--full" ><span className="Button-content">Pokračovat</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            detailListVal.value.map((item, index) => (
                                <div key={index} className="Container">
                                    <div className="exekuce-InfoCount m-15 pb-15">
                                        <table key={index} className="TableDataPair TableDataPair--mobileWrap mw-auto">
                                            <tbody>
                                                <tr>
                                                    <th>Spisová značka:</th>
                                                    <td><strong>{item.ex_cislo}</strong></td>
                                                </tr>
                                                <tr>
                                                    <th>Popis:</th>
                                                    <td><strong dangerouslySetInnerHTML={{ __html: item.description }}></strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="Grid Grid--alignRight gap-30 gmb-10">
                                            <div className="Grid-cell smw:w-auto Flex Flex--alignMiddle Flex--justifyCenter detailPrice">
                                                <span className="Price Price--small smaller">{DetailTicketPrice} Kč</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }                      
                        <div className="Container">
                            <div className="Flex Flex--justifyCenter  pt-5 pb-20">
                                +<a className="cursor-pointer" onClick={()=>history.goBack()}>Přidat další</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
