import React from 'react'
import Moment from 'react-moment'
import { DocumentResult, ExekuceSubject } from '../../models/exekuceModel'


interface Props {
    document: DocumentResult;
    subjects: ExekuceSubject[] | null;
}

const TypOsoby = {
    F: "Fyzická osoba",
    P: "Právnická osoba",
}

export default function ExekuceDetailInnerItem({ document, subjects }: Props) {
    function typOsoby(param: string | undefined) {
        switch (param) {
            case 'F':
                return TypOsoby["F"];
            case 'P':
                return TypOsoby["P"];
            default:
                return TypOsoby["F"];
        }
    }

    return (
        <>
            <h3 className="GamaSize mb-20 font-normal">Dokument exekuce</h3>
            {
                document.subjects?.map((item, index) => {
                    let subj = subjects?.find(({ subject_id }) => subject_id === item);
                    return (
                        <table key={index} className="TableDataPair TableDataPair--thWide TableDataPair--mobileWrap TableDataPair--lightBlue mb-20">
                            <tbody>
                                {subj?.jmeno != undefined && subj.jmeno.length > 0 &&
                                    <tr>
                                        <th>Jméno a přijmení</th>
                                        <td><strong>{subj.jmeno}</strong></td>
                                    </tr>
                                }
                                {subj?.typ_osoby != null &&
                                    <tr>
                                        <th>Typ osoby</th>
                                        <td><strong>{typOsoby(subj?.typ_osoby)}</strong></td>
                                    </tr>
                                }
                                {subj?.datum_narozeni != null &&
                                    <tr>
                                        <th>Datum narození</th>
                                        <td><strong><Moment format='DD. MM. YYYY'>{subj.datum_narozeni ?? ""}</Moment></strong></td>
                                    </tr>
                                }
                                {subj?.adresa != null &&
                                    <tr>
                                        <th>Adresa</th>
                                        <td><strong>{subj.adresa}</strong></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    )
                })
            }

            <table className="TableDataPair TableDataPair--thWide TableDataPair--mobileWrap">
                <tbody>
                    {document.document_type_id != undefined && document?.document_type_id.length > 0 &&
                        <tr>
                            <th>Číselné označení typu dokumentu</th>
                            <td><strong>{document.document_type_id}</strong></td>
                        </tr>
                    }
                    {document.document_type_description != undefined && document?.document_type_description.length > 0 &&
                        <tr>
                            <th>Slovní označení typu dokumentu</th>
                            <td><strong>{document.document_type_description}</strong></td>
                        </tr>
                    }
                    {document.jmeno_soudu != undefined && document?.jmeno_soudu.length > 0 &&
                        <tr>
                            <th>Název soudu</th>
                            <td><strong>{document.jmeno_soudu}</strong></td>
                        </tr>
                    }
                    {document.document_vydano != null &&
                        <tr>
                            <th>Datum, kdy uvedený soud vydal dokument</th>
                            <td><strong><Moment format='DD. MM. YYYY'>{document.document_vydano ?? ""}</Moment></strong></td>
                        </tr>
                    }
                    {document.document_vlozeno != null &&
                        <tr>
                            <th>Datum vložení dokumentu</th>
                            <td><strong><Moment format='DD. MM. YYYY'>{document.document_vlozeno ?? ""}</Moment></strong></td>
                        </tr>
                    }
                    {document.document_modified != null &&
                        <tr>
                            <th>Datum poslední úpravy dokumentu</th>
                            <td><strong><Moment format='DD. MM. YYYY'>{document.document_modified ?? ""}</Moment></strong></td>
                        </tr>
                    }
                     {document.text != undefined && document?.text.length > 0 &&
                        <tr>
                            <th>Popis nebo doplňující informace k dokumentu</th>
                            <td><strong>{document.text}</strong></td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    )
}