import axios from "axios";

export default axios.create({
  baseURL: `${process.env.PUBLIC_URL}/Exekuce`,
  headers: {
    "Content-type": "application/json"
  }
});



export const admin = axios.create({
  baseURL: `${process.env.PUBLIC_URL}/Admin`,
  headers: {
    "Content-type": "application/json"
  }
});



export const signoutReq = axios.create({
  headers: {
    "Content-type": "application/json"
  }
});
