import React from "react";
//import ReactGA from 'react-ga'
import TagManager from "react-gtm-module";

export let tmpTitle: string = "";
export let tmpDesc: string = "";

export default function ChangeTitleDescription(title: string, desc: string) {
  tmpTitle = title;
  tmpDesc = desc;

  document.title = tmpTitle;
  document.getElementById("metaDesc")!.setAttribute("content", tmpDesc);

  //ReactGA.set({ page: location.pathname });
  //ReactGA.pageview(location.pathname);

  // TagManager.dataLayer({
  //   dataLayer: {
  //     event: "page_view",
  //     url: location.pathname,
  //   },
  // });
}
