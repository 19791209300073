
import React, { Component } from "react";

export interface IFindSubmitButton {
  label?: string;
  classes?: string;
  disabled?: boolean;
}

export default class FindSubmitButton extends Component<IFindSubmitButton> {
  static defaultProps = { label: "", classes: "", disabled: false };

  render() {
    return (
      <>
        <button className={"Button " + this.props.classes} type="submit" disabled={this.props.disabled}>
          <span className="Button-content">{this.props.label}</span>
        </button>
      </>
    );
  }
}