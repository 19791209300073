import { createSlice } from '@reduxjs/toolkit';
import { OrderValues } from '../../models/orderModel'

export const slice = createSlice({
    name: 'order',
    initialState: {
        value: new OrderValues(),
    },
    reducers: {
        setSectionFindType: (state, action) => {
            state.value.initType = action.payload;
        },
        setFindData: (state, action) => {
            state.value.firstName = action.payload.firstName;
            state.value.lastName = action.payload.lastName;
            state.value.bornDate = action.payload.bornDate;
            state.value.rc = action.payload.rc;
            state.value.ic = action.payload.ic;
            state.value.price = action.payload.price;
        },
        setPayData: (state, action) => {
            state.value.id = action.payload.id;
            state.value.email = action.payload.email;
            state.value.paymentType = action.payload.paymentType;
            state.value.price = action.payload.price;
            state.value.invoiceData = action.payload.invoiceData;
        },
        setPayState: (state, action) => {
            state.value.isPayed = action.payload;
        }
    },
});

export const { setSectionFindType, setFindData, setPayData, setPayState } = slice.actions;

export const selectOrderValue = (state: { order: { value: OrderValues; }; }) => state.order.value;

export default slice.reducer;