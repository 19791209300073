import { ErrorMessage, Form, Formik, useField } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup'
import ContactValues from '../../models/contactModel'
import BaseInput from '../_base/baseInput'
import CheckBox from '../_base/checkbox';
import { MainInputComponent } from '../_base/mainInput';
import SendData from '../../BL/contactBL';

declare module 'yup' {
    export interface StringSchema {
        phoneValidator(
            msg?: string
        ): StringSchema;
    }
}

export default function ContactFormModal() {
    const [isOpened, setIsOpened] = useState(false);
    const validSchema = createValidationSchema();
    const [contactVal, setContactVal] = useState(new ContactValues());
    const [submitted, setSubmitted] = useState(false);
    const [isError, setError] = useState(false);

    function createValidationSchema() {
        Yup.addMethod(Yup.string, 'phoneValidator', function yupPhone(msg) {
            return this.test('phoneValTest', msg, function (value) {
                var phone = new String(value);
                var number = 0;
                var i;
                var isValid = true;

                phone = phone.toUpperCase();
                while (phone != phone.replace(" ", "")) {
                    phone = phone.replace(" ", "");
                }

                if (phone.length > 0) {
                    for (i = 0; i < phone.length; i++) {
                        if (new String("01234567890").indexOf(phone.substr(i, 1)) >= 0)
                            number++;
                    }
                    if (number < 9) {
                        isValid = false;
                    }

                    if (phone.match(/^[+]?[0-9 ]{9,}$|\N\E\M\Á/) == null) {
                        isValid = false;
                    }
                }

                return isValid;
            })
        });

        return Yup.object({
            firstName: Yup.string().required('Prosím zadejte položku Jméno'),
            lastName: Yup.string().required('Prosím zadejte položku Příjmení'),
            phone: Yup.string().phoneValidator('Nesprávna hodnota Telefon.').required('Prosím zadejte položku Telefon'),
            email: Yup.string().email('Email nemá správný formát')
                .required('Prosím zadejte položku Email'),
            message: Yup.string().required('Prosím zadejte položku Zpráva'),
            cGdpr: Yup.boolean().oneOf([true], 'Prosím odsouhlaste zadané údaje!')
        });
    }

    const handleSubmit = (data: ContactValues) => {
        SendData.create(data)
            .then(response => {
                setSubmitted(true);
            })
            .catch(e => {
                setError(true);
            });
    };

    return (
        <>
            <a className="Button Button--lightBlue" onClick={() => { setIsOpened(true); document.documentElement.classList.add('is-scrolling-disabled'); }}>
                <span className="Button-content">
                    Kontaktní formulář
                    </span>
                <span className="Icon Icon--arrowRight"></span>
            </a>
            <div className={isOpened ? "Modal exekuce-ModalRecap is-visible is-active" : "Modal exekuce-ModalRecap"}>
                <div className="Modal-background" data-ref="background"></div>
                <div className="Modal-layer" data-ref="layer">
                    <div className="Modal-inner">
                        <div className="Modal-content" data-ref="content">
                            <div className="Modal-text" data-ref="text">
                                {submitted ? (
                                    <article className="Confirmation Gama text-center">
                                        {!isError ? (
                                            <>
                                                <span className="VisualIcon VisualIcon--circleCheck" ></span>
                                                <h3 className="Confirmation-title Beta">Děkujeme</h3>
                                                <p>Vaše zpráva byla úspěšně odeslána.</p>
                                                <p>Brzy se vám ozveme.</p>
                                            </>
                                        ) : (
                                            <>
                                                <span className="VisualIcon VisualIcon--circleWarning" ></span>
                                                <h3 className="Confirmation-title Beta is-invalid">Bohužel nastala chyba komunikace</h3>
                                                <p>Prosím opakujte požadavek později.</p>
                                            </>
                                        )}
                                    </article>
                                ) : (
                                    <>
                                        <h2 className="Section-title md:text-center Beta">Kontaktní formulář</h2>
                                        <Formik initialValues={contactVal} validationSchema={validSchema} onSubmit={values => handleSubmit(values)}>
                                            <Form className="homepage-Form" data-component="HomepageForm">
                                                <div className="Container">
                                                    <div className="Grid gap-20 mb-30">
                                                        <div className="FormItem smw:w-1/2">
                                                            <BaseInput name="firstName" label="Jméno" />
                                                        </div>
                                                        <div className="FormItem smw:w-1/2">
                                                            <BaseInput name="lastName" label="Příjmení" />
                                                        </div>
                                                        <div className="FormItem smw:w-1/2">
                                                            <BaseInput name="email" label="E-mail" />
                                                        </div>
                                                        <div className="FormItem smw:w-1/2">
                                                            <BaseInput name="phone" label="Telefon" />
                                                        </div>
                                                        <div className="FormItem FormItem--full">
                                                            <BaseInput name="message" label="Zpráva" component={MainInputComponent.textarea} />
                                                        </div>
                                                    </div>
                                                    <div className="Grid gap-20 gmb-15 Grid--alignMiddle mb-10">
                                                        <div className="FormItem FormItem--checkbox smw:w-1/2">
                                                            <CheckBox name="cGdpr" checked={false} label="Souhlasím se zpracováním <br className='smwMax:u-hidden' /><a class='Link' href='https://www.cebia.cz/pravni-informace/osobni-udaje' target='_blank'>osobních údajů</a>" />
                                                        </div>
                                                        <div className="FormItem smw:w-1/2">
                                                            <button type="submit" className="Button Button--block" name="_submit" value="Odeslat zprávu">
                                                                <span className="Button-content">Odeslat zprávu</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </>
                                )}
                            </div>
                            <button type="button" className="Modal-close" onClick={() => { setIsOpened(false); document.documentElement.classList.remove('is-scrolling-disabled'); }}>Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
