import React from 'react'
import '../../css/fullscreen.css'

const clickEvent = isMobile() ? 'touchstart' : 'click'

export interface ISourceData {
  src: string;
  srcWebP: string;
  src2: string;
  src2WebP: string;
  Name: string
}

type ImageGroupProps = {
  transitionMs?: number
  children: React.ReactNode
}

type ImageGroupStateTypes = {
  currentFocusedImageIndex: number
  isImageGroupExpanded: boolean
  shouldAnimate: boolean
}

type ImageGroupActionTypes = {
  type: string
  payload?: any
}

type ImageProps = {
  src: ISourceData
  alt: string
  style?: object
  [k: string]: any
}

function reducer(
  state: ImageGroupStateTypes,
  action: ImageGroupActionTypes
): ImageGroupStateTypes {
  switch (action.type) {
    case ImageGroup.actions.toggleExpand:
      return {
        ...state,
        currentFocusedImageIndex: action.payload.id,
        isImageGroupExpanded: true,
        shouldAnimate: false,
      }
    case ImageGroup.actions.toggleExpandAnimate:
      return {
        ...state,
        currentFocusedImageIndex: action.payload.id,
        isImageGroupExpanded: true,
        shouldAnimate: true,
      }
    case ImageGroup.actions.toggleCloseAnimate:
      return {
        ...state,
        currentFocusedImageIndex: -1,
        isImageGroupExpanded: false,
        shouldAnimate: true,
      }
    default:
      throw new Error(`No case defined for type '${action.type}'.`)
  }
}

export function ImageGroup({
  transitionMs = 250,
  children,
}: ImageGroupProps): any {
  const isAnimating = React.useRef(false)

  const [state, dispatch] = React.useReducer(reducer, {
    currentFocusedImageIndex: -1,
    isImageGroupExpanded: false,
    shouldAnimate: true,
  })

  const { updatedChildren, numberOfImageChildren } = mapPropsToChildren(
    children,
    (child, index) =>
      React.cloneElement(child, {
        'data-fullscreen-id': index,
        isFocused: index === state.currentFocusedImageIndex,
        shouldAnimate: state.shouldAnimate,
        isImageGroupExpanded: state.isImageGroupExpanded,
        transitionMs,
        onClick: () => {
          // isAnimating flag allows us to prevent another dispatch
          // happening while an animation is taking place.
          if (!isAnimating.current) {
            if (state.shouldAnimate) {
              isAnimating.current = true
              setTimeout(() => {
                isAnimating.current = false
              }, transitionMs)
            }

            if (index === state.currentFocusedImageIndex) {
              dispatch({
                type: ImageGroup.actions.toggleCloseAnimate,
              })
            } else {
              dispatch({
                type:
                  state.currentFocusedImageIndex === -1
                    ? ImageGroup.actions.toggleExpandAnimate
                    : ImageGroup.actions.toggleExpand,
                payload: {
                  id: index,
                },
              })
            }
          }
        },
      })
  )

  // Effect to attach and remove event listeners.
  React.useEffect(() => {
    // Handles outer click to exit out of image
    let clickListener = (e: any) => {
      if (e.target.hasAttribute('data-fullscreen-group')) {
        dispatch({
          type: ImageGroup.actions.toggleCloseAnimate,
        })
      }
    }

    let keyDownListener = (e: any) => {
      if (state.currentFocusedImageIndex !== -1) {
        if (e.key === 'Escape') {
          dispatch({
            type: ImageGroup.actions.toggleCloseAnimate,
          })
        }
      }
    }

    let resizeListener = () => {
      dispatch({
        type: ImageGroup.actions.toggleCloseAnimate,
      })
      window.removeEventListener('resize', resizeListener)
    }

    let scrollAnimationId = -1
    let initialOffset = 0
    let scrollListener = () => {
      scrollAnimationId = requestAnimationFrame(scrollListener)
      const difference = Math.abs(initialOffset - window.pageYOffset)
      if (difference > 80) {
        dispatch({
          type: ImageGroup.actions.toggleCloseAnimate,
        })
      }
    }

    if (state.isImageGroupExpanded) {
      window.addEventListener(clickEvent, clickListener)
      window.addEventListener('keydown', keyDownListener)
      window.addEventListener('resize', resizeListener)
      initialOffset = window.pageYOffset
      scrollAnimationId = requestAnimationFrame(scrollListener)
    } else {
      window.removeEventListener(clickEvent, clickListener)
      window.removeEventListener('keydown', keyDownListener)
      window.removeEventListener('resize', resizeListener)
      cancelAnimationFrame(scrollAnimationId)
    }

    return () => {
      window.removeEventListener(clickEvent, clickListener)
      window.removeEventListener('keydown', keyDownListener)
      window.removeEventListener('resize', resizeListener)
      cancelAnimationFrame(scrollAnimationId)
    }
  }, [
    state.isImageGroupExpanded,
    numberOfImageChildren,
    state.currentFocusedImageIndex,
  ])

  return (
    <div data-fullscreen-group="" className={`Grid smw:gap-20 lg:gap-40 gmb-20 Grid--alignCenter fullscreen-group${state.isImageGroupExpanded ? ' fullscreen-group--expanded' : ''}`}
      style={{ transition: `opacity ${transitionMs}ms ease`, }}>
      {updatedChildren}
      {state.currentFocusedImageIndex !== -1 && (
        <>
          <button className="fullscreen-exit-btn" onClick={() => { dispatch({ type: ImageGroup.actions.toggleCloseAnimate, }) }} aria-label="Close fullscreen view">
            <ExitIcon />
          </button>
        </>
      )}
    </div>
  )
}

ImageGroup.actions = {
  toggleExpand: 'TOGGLE_EXPAND',
  toggleExpandAnimate: 'TOGGLE_EXPAND_ANIMATE',
  toggleCloseAnimate: 'TOGGLE_CLOSE_ANIMATE',
}

export function Image({ src, alt, style, ...props }: ImageProps) {
  const {
    onClick,
    isFocused,
    shouldAnimate,
    isImageGroupExpanded,
    transitionMs,
    ...rest
  } = props

  const scalingImage = React.useRef<HTMLDivElement>(null)
  const wasPreviouslyFocused = React.useRef(false)
  const initialRender = React.useRef(false)
  React.useEffect(() => {
    if (!initialRender.current) {
      initialRender.current = true
      return
    }

    const element = scalingImage.current
    if (element) {
      if (isFocused) {
        if (shouldAnimate) {
          // Animate in
          calculatePosition('open')(element, transitionMs)
        } else {
          // Immediately show
          calculatePosition('open')(element, 0)
        }
        wasPreviouslyFocused.current = true
      }
      if (!isFocused && wasPreviouslyFocused.current) {
        if (shouldAnimate) {
          // Animate out
          calculatePosition('close')(element, transitionMs)
        } else {
          // Immediately hide
          calculatePosition('close')(element, 0)
        }

        wasPreviouslyFocused.current = false
      }
    }
  }, [isFocused, shouldAnimate, transitionMs])

  return (
    <div className="Grid-cell smw:w-1/3">
      <div className="article-Card article-Card--simple">
        <div className="fullscreen-container article-Card-body" {...rest}>
          <button className="fullscreen-btn" onClick={onClick} tabIndex={isFocused || !isImageGroupExpanded ? 0 : -1}>
            <div className="fullscreen-image article-Card-image">
            <picture>
                <source srcSet={src.srcWebP} type="image/webp" />
                <source srcSet={src.src} type="image/jpg" />
                <img src={src.src} alt={alt} className="imageBorder"/>
              </picture>      
            </div>
            <div ref={scalingImage} className="fullscreen-image display-none">
              <picture>
                <source srcSet={src.src2WebP} type="image/webp" />
                <source srcSet={src.src2} type="image/jpg" />
                <img src={src.src2} alt={alt} className="imgMax"/>
              </picture>
            </div>
          </button>
          <div className="article-Card-content">
            <h3 className="article-Card-title">{src.Name}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

Image.displayName = 'Image'

function mapPropsToChildren(
  children: React.ReactNode,
  fnToApplyToChild: (child: any, index: number) => React.ReactNode
) {
  let numberOfImageChildren = 0

  const recursiveMap = (children: React.ReactNode): React.ReactNode => {
    return React.Children.map(children, child => {
      // @ts-ignore
      if (child.type.displayName === Image.displayName) {
        child = fnToApplyToChild(child, numberOfImageChildren)
        numberOfImageChildren++
        return child
      }

      if (!React.isValidElement(child)) {
        return child
      }

      // @ts-ignore
      if (child.props.children) {
        // @ts-ignore
        child = React.cloneElement(child, {
          // @ts-ignore
          children: recursiveMap(child.props.children),
        })
      }

      return child
    })
  }

  const updatedChildren = recursiveMap(children)

  return {
    updatedChildren,
    numberOfImageChildren,
  }
}

function calculatePosition(action: 'open' | 'close') {
  return function calculate(el: HTMLDivElement, transitionMs: number = 0) {
    if (action === 'open') {
      const { innerWidth, innerHeight } = window
      const { height, width, top, left } = el.getBoundingClientRect()
      const scaleBy = innerWidth < innerHeight ? 'width' : 'height'
      const scale = scaleBy === 'width' ? innerWidth / width : innerHeight / height

      let translateY: number = 0

      if (scaleBy === 'width') {
        const scaledImageHeight = height * scale
        const centerOfScreen = innerHeight / 2

        const topOfWhereImageShouldBe = centerOfScreen - scaledImageHeight / 2

        translateY = (topOfWhereImageShouldBe - top) / scale
      } else {
        translateY = (top / scale) * -1
      }

      el.style.opacity = '1'
      el.style.transition = `transform ${transitionMs}ms ease, opacity 0ms`
      el.style.transformOrigin = '50% 0'
      el.style.zIndex = '999'
      el.style.pointerEvents = 'initial'
      el.style.touchAction = 'initial'
      el.style.position = 'fixed'
      el.style.top = '50%'
      el.style.left = '50%'
      el.style.transform = 'translate(-50%, -50%)'
      el.style.display = 'inherit'
      el.style.width = '95%'
      el.style.display = 'flex';
      el.style.justifyContent = 'center';
      return
    }

    if (action === 'close') {
      el.style.opacity = '0'
      el.style.transform = `scale(1) translate3d(0px, 0px, 0px)`
      el.style.transition = `transform ${transitionMs}ms ease, opacity 0ms ease ${transitionMs}ms, z-index 0ms ease ${transitionMs}ms`
      el.style.transformOrigin = '50% 0'
      el.style.zIndex = '-1'
      el.style.pointerEvents = 'none'
      el.style.touchAction = 'none'
      el.style.display = 'none'
      return
    }
  }
}

function isMobile() {
  if (typeof document !== `undefined`) {
    return 'ontouchstart' in document.documentElement === true
  }
  return false
}

function ExitIcon() {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="#fff" fillRule="nonzero" stroke="#fff">
        <path d="M18.6753571 2.08152486l-.6487597-.67476795c-.3578377-.37260221-.9393896-.37260221-1.2979286 0L9.98071429 8.33578453 3.21534416 1.30137569c-.35848052-.37254144-.93974026-.37254144-1.29792858 0l-.64875974.6746464c-.3581883.37272377-.3581883.97644752 0 1.34923205l8.055 8.37634806c.35818832.3729061.93898052.3729061 1.29757793 0l8.05412333-8.27102761c.359065-.37254144.359065-.97650829 0-1.34904973z" />
        <path d="M18.6753571 17.91847514l-.6487597.67476795c-.3578377.37260221-.9393896.37260221-1.2979286 0l-6.74795451-6.92902762-6.76537013 7.03440884c-.35848052.37254144-.93974026.37254144-1.29792858 0l-.64875974-.6746464c-.3581883-.37272377-.3581883-.97644752 0-1.34923205l8.055-8.37634806c.35818832-.3729061.93898052-.3729061 1.29757793 0l8.05412333 8.27102761c.359065.37254144.359065.97650829 0 1.34904973z" />
      </g>
    </svg>
  )
}
