import { ImageGroup, Image, ISourceData } from '../_base/fullscreen'

import bezExekuce from "../../assets/images/pages/Samples/Preview/Bez_exekuce_nahled.jpg";
import bezExekuceWebP from "../../assets/images/pages/Samples/Preview/Bez_exekuce_nahled.webp";
import predExekuce from "../../assets/images/pages/Samples/Preview/Prehled_exekuci_nahled.jpg";
import predExekuceWebP from "../../assets/images/pages/Samples/Preview/Prehled_exekuci_nahled.webp";
import detailExekuce from "../../assets/images/pages/Samples/Preview/Detail_exekuce_nahled.jpg";
import detailExekuceWebP from "../../assets/images/pages/Samples/Preview/Detail_exekuce_nahled.webp";

import bezExekuceB from "../../assets/images/pages/Samples/Bez_exekuci.jpg";
import bezExekuceWebPB from "../../assets/images/pages/Samples/Bez_exekuci.webp";
import predExekuceB from "../../assets/images/pages/Samples/Prehled_exekuci.jpg";
import predExekuceWebPB from "../../assets/images/pages/Samples/Prehled_exekuci.webp";
import detailExekuceB from "../../assets/images/pages/Samples/Detail_exekuce.jpg";
import detailExekuceWebPB from "../../assets/images/pages/Samples/Detail_exekuce.webp";


export default function Example() {
    const images: ISourceData[] = [
        { src: bezExekuce, srcWebP: bezExekuceWebP, src2: bezExekuceB, src2WebP: bezExekuceWebPB, Name: "Bez exekuce" },
        { src: predExekuce, srcWebP: predExekuceWebP, src2: predExekuceB, src2WebP: predExekuceWebPB, Name: "Přehled exekucí" },
        { src: detailExekuce, srcWebP: detailExekuceWebP, src2: detailExekuceB, src2WebP: detailExekuceWebPB, Name: "Detail exekuce" }
    ]

    return (
        <>
            <section className="Section Section--appended SectionHomepageArticles" id="section-ukazka-vysledku">
                <div className="Container">
                    <h2 className="Section-title Section-title--center Beta">Ukázka výsledku</h2>
                    <ImageGroup>
                        {images.map(i => (
                            <Image src={i} alt="Ukázka" />
                        ))}
                    </ImageGroup>
                </div>
            </section>
        </>
    );
}
