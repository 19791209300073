import BaseInput from '../_base/baseInput'
import { ExekuceTicketPrice } from '../../models/orderModel';
import { selectOrderValue } from '../../BL/features/orderSlice';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';

export default function SubjectName() {
    const oldVal = useSelector(selectOrderValue);
    
    return (
        <>
            <div className="Section Section--medium">
            <div className="Container">
                    <div className="Form-headline text-left">
                        Prověřovaný subjekt: <strong>{oldVal.firstName} {oldVal.lastName}, <Moment format='DD. MM. YYYY'>{oldVal.bornDate?.toString()}</Moment></strong>
                    </div>
                </div>
                <div className="Container text-left">
                    <p><strong className="color-red">Upozornění:</strong> Zkontrolujte prosím správnost zadaných údajů. Po zaplacení kontroly je již nelze změnit.</p>
                </div>
            </div>
            <div className="Container">
                <div className="Separator"></div>
            </div>
            <div className="Section Section--slim">
                <div className="Container">
                    <h2 className="Form-subtitle smaller text-left">Zkontrolovat subjekt i podle rodného čísla <span className="color-orange">(+ {ExekuceTicketPrice} Kč)</span></h2>
                    <div className="Grid gap-20 lg:gap-50 Grid--alignCenter just-flex-start">
                        <div className="FormItem smw:w-6/12 md:w-6/12 lg:w-4/12">
                            <BaseInput inputFilter="[^\d]" name="rc" label="Rodné číslo (bez lomítka a mezer)" maxLenght={10}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Container">
                <div className="Separator"></div>
            </div>
        </>
    )
}