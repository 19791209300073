import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { loaderValue } from '../../BL/features/loaderSlice';


interface Props {
    content?: string;
}

export default function LoadingComponent({ content = 'Načítáme data...' }: Props) {
    const isVisible = useSelector(loaderValue);
 
    return (
        <>
           <div className={`Loader Loader--fullscreen ${isVisible ? "is-active" : ""} inputDateWidth`}>
                <div className="Loader-icon">
                    <div className="TripleCircle"></div>
                </div>
                <span className="Loader-text">{content}</span>
            </div>
        </>        
    )
}