import {
  InvoiceData,
  OrderValues,
  SectionFindType,
} from "../models/orderModel";
import {
  setFindData,
  setPayData,
  setPayState,
  setSectionFindType,
} from "./features/orderSlice";
import {
  DetailResult,
  ExekuceData,
  ExekuceResult,
} from "../models/exekuceModel";
import http from "./http-common";
import React, { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "./features/loaderSlice";
import { useHistory } from "react-router";
import { addDetail } from "./features/detailSlice";
import TagManager from "react-gtm-module";

export const handlerSubmitAction = (
  values: OrderValues,
  dispatch: any,
  history: any
) => {
  let destUrl = "/order/s1/";

  dispatch(setFindData(values));

  if (values.initType === SectionFindType.Ic) {
    destUrl += "Ic/";
  } else if (values.initType === SectionFindType.Name) {
    destUrl += "Name/";
  }

  history.push(destUrl);
};

export const handlerSumbitPayAction = (
  values: OrderValues,
  dispatch: any,
  history: any,
  captchaToken: string
) => {
  dispatch(showLoader());
  dispatch(setFindData(values));

  if (captchaToken.length > 0) values.token = captchaToken;

  http
    .post("/UpdateOrder", values)
    .then((response) => {
      window.location.assign(response.data);
    })
    .catch((x) => {
      dispatch(hideLoader());
    });

  dispatch(setPayState(-1));
};

export function getOrder(
  id: string | undefined,
  dispatch: Dispatch<any>,
  setOrdData: React.Dispatch<React.SetStateAction<OrderValues>>,
  history: any
) {
  if (id && id.length > 0) {
    dispatch(showLoader());

    http
      .post<OrderValues>("/GetOrder?id=" + id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.isPayed === 1) {
            if (response.data.category.length > 0) {
              const items = [];
              switch (response.data.category) {
                case "ico":
                  items.push({
                    item_name: "ic",
                    price: response.data.value.toFixed(2),
                    quantity: 1,
                  });
                  break;
                case "jmeno":
                  items.push({
                    item_name: "jmeno_prijmeni",
                    price: response.data.value.toFixed(2),
                    quantity: 1,
                  });
                  break;
                case "rc":
                  items.push({
                    item_name: "rodne_cislo",
                    price: response.data.value.toFixed(2),
                    quantity: 1,
                  });
                  break;
                case "jmeno_rc":
                  items.push({
                    item_name: "rodne_cislo",
                    price: 59.0,
                    quantity: 1,
                  });
                  items.push({
                    item_name: "jmeno_prijmeni",
                    price: 59.0,
                    quantity: 1,
                  });
                  break;
              }

              TagManager.dataLayer({
                dataLayer: {
                  event: "purchase",
                  ecommerce: {
                    currency: "CZK",
                    value: response.data.value.toFixed(2),
                    items: items,
                  },
                },
              });
            }

            history.push("/order/result/" + id);
          } else {
            if (response.data.causeIds && response.data.causeIds.length > 0) {
              let cause = response.data.causeIds.split("|");

              cause.map((item, index) => {
                dispatch(addDetail(item));
              });
            }

            setOrdData(response.data);
          }
        }
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  }
}

export function getResult(
  id: string,
  updateItems: React.Dispatch<React.SetStateAction<ExekuceData>>,
  dispatch: Dispatch<any>
): ExekuceData {
  let result = new ExekuceData();

  if (id && id.length > 0) {
    http.get<ExekuceData>("/GetExekuce?id=" + id).then((response) => {
      if (response.data) result = response.data;
      updateItems(result);
      dispatch(hideLoader());
    });
  } else {
  }

  return result;
}

export function getExekuceDetail(
  id: string | null,
  exCislo: string | null
): DetailResult {
  let result = new DetailResult();

  if (id && id.length > 0 && exCislo && exCislo.length > 0) {
    http
      .get<DetailResult>("/GetExekuceDetail?id=" + id + "&exCislo=" + exCislo)
      .then((response) => {
        result = response.data;
      });
  }

  return result;
}

export function setFindValue(
  value: SectionFindType,
  dispatch: Dispatch<any>,
  orderValue: OrderValues
) {
  dispatch(setSectionFindType(value));
  dispatch(hideLoader());
  orderValue.id = "0";
  //pri prokliku z penize.cz se v url objevi IC a to se setuje ve FindByIc do OrderValu aby se zobrazila hodnota v IC inputu.
  //jenomze to spusobilo problem ze to zustavalo ulozene v Reduxu a platba byla za 2 dotazy -> CHYBA NA MAX.
  //proto pri zmene FindType muzu rovnou vynulovat IC protoze se meni URL a uz ta hodnota IC neni v URL.
  orderValue.ic = "";
}

export const redirectToHome = (
  orderId: string | undefined,
  urlId: string | undefined,
  history: any
) => {
  if (orderId === undefined && urlId === undefined) {
    history.push("/");
  }
};
