import React, { Component } from 'react'
import SideMenu from '../_base/SideMenu/sideMenu'

export class WhyDoIt extends Component {
    render() {
        return (
            <section className="Section Section--appended">
        <div className="Container">
            <article className="homepage-Why" data-component="HomepageWhy">
                <div className="homepage-Why-header">
                    <h2 className="Beta">Proč kontrolovat exekuce</h2>
                    <p>Nejčastější důvody proč si lidé dělají kontrolu</p>
                </div>
                <div className="homepage-Why-main">
                  <SideMenu id="sdMenu1" isRightSide={false} >

                  </SideMenu>
                </div>
            </article>
        </div>
    </section>
            );
        }
     }
    
    export default WhyDoIt;