import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, useField } from 'formik'
import * as Yup from 'yup'
import SubscriberValues from '../../models/subscriberModel'
import BaseInput from './baseInput';
import { MainInputType } from './mainInput';
import SendData from '../../BL/suscriberBL';

export default function SubscriberForm() {
    const validSchema = createValidationSchema();
    const [subscriberVal, setSubscriberVal] = useState(new (SubscriberValues));
    const [submitted, setSubmitted] = useState(false);
    const [isError, setError] = useState(false);

    function createValidationSchema() {
        return Yup.object({
            emailNews: Yup.string().email("Email má nesprávný formát!")
                .required('Prosím zadejte položku Email'),
            isGdprNews: Yup.boolean().oneOf([true], 'Prosím odsouhlaste zadané údaje!')
        });
    }

    const handleSubmit = (data: SubscriberValues) => {
        SendData.create({ emailAddress: data.emailNews })
            .then(response => {
                setSubmitted(true);
            })
            .catch(e => {
                setError(true);
            });
    };

    return (
        <>
            {submitted ? (
                !isError ? (<p className="newsletter-Form-confirmation">Odesláno, děkujeme.</p>) 
                : (<p className="newsletter-Form-confirmation Error">Bohužel nastala chyba komunikace. Prosím opakujte požadavek později.</p>)
            ) : (
                <Formik initialValues={subscriberVal} validationSchema={validSchema} onSubmit={values => handleSubmit(values)}>
                    <Form className="newsletter-Form">
                        <div className="FormItem FormItem--input">
                            <BaseInput name="emailNews" className="newsletter-Form-input Input Input--dark" placeholder="Vaše e-mailová adresa" inputType={MainInputType.email} />
                        </div>
                        <div className="FormItem FormItem--checkbox u-errorContainer">
                            <span className="Checkbox Checkbox--dark">
                                <Field type="checkbox" className="Checkbox-input" aria-label="Souhlas se zpracováním osobních údajů" name="isGdprNews" id="isGdprNews" />
                                <label className="Checkbox-placeholder" htmlFor="isGdprNews"></label>
                                <span className="Checkbox-label">
                                    <label htmlFor="isGdprNews">Souhlasím se zpracováním</label> <a target="_blank" className="Link" href="https://www.cebia.cz/pravni-informace/osobni-udaje">osobních údajů</a>
                                </span>
                            </span>
                            <ErrorMessage name="isGdprNews" component="div" className="InputError" />
                        </div>
                        <div className="FormItem FormItem--button">
                            <button type="submit" className="Button newsletter-Form-button" aria-label="Odebírat"
                                value="Odebírat">
                                <span className="Button-content">Odebírat</span>
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </>
    );
}
