import React from 'react'
import { ExekuceData } from '../../models/exekuceModel'
import Moment from 'react-moment'

interface Props {
    subject: ExekuceData;
}


export default function ExekuceSubject({ subject }: Props) {
    return (
        <>
            <section className="Section Section--appended SectionShapeHalfUp">
                <div className="Container Container--medium">
                    <div className="Section-shape Section-shape--right"></div>
                    <article className="exekuce-Overview">
                        <div className="Grid Grid--alignCenter Grid--alignMiddle gmb-15">
                            <div className="Grid-cell smw:w-8/12 md:w-5/12 lg:w-4/12">
                                <div className="exekuce-Overview-content">
                                    {((subject.ic?.length ?? 0 > 0) || (subject.rc?.length ?? 0 > 0)) &&
                                        <table className="TableDataPair TableDataPair--rc">
                                            <tbody>
                                                {(subject.ic?.length ?? 0) > 0 &&

                                                    <tr>
                                                        <th className="w-1/2">IČ</th>
                                                        <td className="w-1/2"><strong>{subject.ic}</strong></td>
                                                    </tr>

                                                }
                                                {(subject.rc?.length ?? 0) > 0 &&
                                                    <tr>
                                                        <th className="w-1/2">Rodné číslo</th>
                                                        <td className="w-1/2"><strong>{subject.rc}</strong></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    <table className="TableDataPair">
                                        <tbody>
                                            {(subject.name?.length ?? 0) > 0 &&
                                                <tr>
                                                    <th className="w-1/2">Jméno</th>
                                                    <td className="w-1/2"><strong>{subject.name}</strong></td>
                                                </tr>
                                            }
                                            {(subject.surname?.length ?? 0) > 0 &&
                                                <tr>
                                                    <th className="w-1/2">Příjmení</th>
                                                    <td className="w-1/2"><strong>{subject.surname}</strong></td>
                                                </tr>
                                            }
                                            {subject.birthDate != null && <tr>
                                                <th className="w-1/2">Datum narození</th>
                                                <td className="w-1/2 text-noWrap"><strong><Moment format='DD. MM. YYYY'>{subject.birthDate}</Moment></strong></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="Grid-cell md:w-1/12"></div>
                            <div className="Grid-cell md:w-6/12 lg:w-7/12">
                                <div className="exekuce-Overview-infoBubble">
                                    <p className="mb-5">
                                        Uvedené informace jsou aktuální k datu a času vygenerování výpisu: <Moment className="text-noWrap" format='DD. MM. YYYY HH:mm:ss'>{subject.created}</Moment>. Odkaz na tento výpis a daňový doklad byl odeslán na vámi zadanou emailovou adresu:
                                        <strong> {subject.email}</strong>
                                        <br />
                                    </p>
                                    <p>VS transakce: <strong>{subject.variable}</strong></p>
                                    <p>
                                        K výpisu lze přistupovat 24 h od jeho vygenerování do: <strong><Moment add={{ days: 1 }} format='DD. MM. YYYY HH:mm:ss'>{subject.created}</Moment></strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}