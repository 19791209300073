import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import orderReducer from './features/orderSlice';
import loaderReducer from './features/loaderSlice'
import detailReducer from './features/detailSlice'
import userReducer from './features/userSlice'

export default configureStore({
    reducer: {
        order: orderReducer,
        loader: loaderReducer,
        exDetail: detailReducer,
        user: userReducer

    },
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});