import React, { Component } from 'react'

export class Submenu extends Component {
    render() {
        return (
        <div className="Section Section--bare">
            <div className="Container Container--medium">
                <nav className="homepage-Links">
                    <a className="homepage-Links-link" href="#section-slider">Vyhledávání</a>
                    <a className="homepage-Links-link" href="#section-jak-to-funguje">O službě</a>
                    <a className="homepage-Links-link" href="#section-ukazka-vysledku">Ukázka výsledku</a>
                    <a className="homepage-Links-link" href="#section-faq">FAQ</a>
                </nav>
            </div>
        </div>
        );
    }
 }

export default Submenu;
