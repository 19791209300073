import React, { Component } from 'react'

export class Warning extends Component {
    render() {
        return (
            <section className="Section Section--appended SectionHomepageWarning">
                <div className="Container is-pure bg-darkBlue color-textBlue">
                    <div className="Container SectionHomepageWarning-container">
                        <div className="Grid gap-60 gmb-30">
                            <div className="Grid-cell md:w-1/2">
                                <span className="VisualIcon VisualIcon--circleWarning" aria-hidden="true"></span>
                                <p>
                                    <strong>Upozornění:</strong><br />
                                    Cebia umožňuje nahlížení do Centrální evidence exekucí, nevede však jednotlivá exekuční řízení a nezodpovídá za správnost uvedených údajů. Chcete-li se dozvědět bližší informace nad rámec informací uvedených ve výpisu z CEE, nebo máte podezření na nesprávnost uvedených informací, musíte se obrátit na konkrétního soudního exekutora, který vede danou exekuci.
                                </p>
                            </div>
                            <div className="Grid-cell md:w-1/2">
                                <span className="VisualIcon VisualIcon--circleCheck" aria-hidden="true"></span>
                                <p>
                                    <strong>Kontrola:</strong><br />
                                    Kontrola exekucí probíhá dvoustupňově. V prvním stupni kontroly získáte seznam všech exekučních řízení vedených na zadaný subjekt. Tím získáte informaci, zda jsou na subjekt vedeny exekuce popřípadě jejich počet. V druhém stupni kontroly získáte detail exekuce s podrobnostmi – na jakou částku je exekuce vedena, typ a popis vymáhané povinnosti apod.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Warning;
