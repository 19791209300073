import React, { Component, createRef, RefObject, ReactNode } from 'react';
import { findDOMNode } from 'react-dom';


export interface AccordionInterface {
    id: string;
}


interface IProps {
    name: string;
    id: string;
    onClickHandler: any;
    isOpened: boolean;
    height: number;
    ref: any;
}

interface IState {
    isActive: boolean;
    onClickHandler: any;
    height: number;
}


export default class Accordion extends Component<AccordionInterface> {
    private _accordionItemsRef: Array<RefObject<any>>;

    constructor(props: AccordionInterface) {
        super(props);

        this._accordionItemsRef = new Array();
    }

    headClickHandler = (sender: any) => {
        let currentId = sender.props.id.replace("_h", "");

        this._accordionItemsRef.forEach((item, index) => {

            if (item?.current?.props != null) {
                let isActive = item!.current!.props!.id === currentId && !sender.isOpen();

                item!.current!.setActive(isActive);
            }
        });
    }

    render() {
        return (
            <>
                {
                    React.Children.map(this.props.children, (child: any, index) => {
                        let elementId = this.props.id + "_" + index.toString();
                        let elementRef = createRef<React.ReactNode>();
                        this._accordionItemsRef.push(elementRef);

                        return React.cloneElement(child, { id: elementId, name: this.props.id, onClickHandler: this.headClickHandler, ref: elementRef });
                    })
                }
            </>
        );
    }
}


export class AccordionItem extends Component<IProps, IState> {
    static defaultProps = { id: "", name: "", onClickHandler: "", isOpened: false, height: 0, ref: null };

    private _bodyRef: RefObject<AccordionBody>;
    private _headRef: RefObject<AccordionHead>;

    constructor(props: any) {
        super(props);

        //todo: doplnit do propsAny
        this.state = { isActive: false, onClickHandler: null, height: 0 };

        this._bodyRef = createRef<AccordionBody>();
        this._headRef = createRef<AccordionHead>();
    }

    setActive(value: boolean) {

        this.setState({ isActive: value });

        let bodyElement = (findDOMNode(this) as HTMLElement).querySelector('.toggle-Body')!;
       
        let height = value ? bodyElement.scrollHeight : 0;

        this!._bodyRef!.current!.setHeight(height);
        this!._headRef!.current!.setAccordionState(value);
    }

    render() {
        return (
            <>
                <article className={`toggle-Item ${this.state.isActive ? "is-active" : ""}`} id={this.props.id}>
                    {
                        React.Children.map(this.props.children,
                            (child: any) => {
                                let postFix = "_h";
                                let currentRef: RefObject<any> = this._headRef;

                                if (child?.type === AccordionBody) {
                                    postFix = "_b";
                                    currentRef = this._bodyRef;
                                }

                                return React.cloneElement(child, { id: this.props.id + postFix, onClickHandler: this.props.onClickHandler, ref: currentRef })
                            })
                    }
                </article>
            </>
        )
    }
}


export class AccordionHead extends Component<IProps, IState> {
    static defaultProps = { id: "", name: "", onClickHandler: "", isOpened: false, height: 0, ref: null };
    constructor(props: any) {
        super(props);

        this.state = { isActive: props.isOpened ?? false, onClickHandler: this.props.onClickHandler, height: 0 };
    }

    changeAccordionState() {
        this.setState({ isActive: !this.state.isActive });
        this.state.onClickHandler(this);
    }

    isOpen(): boolean {
        return this.state.isActive;
    }

    setAccordionState(state: boolean) {
        this.setState({ isActive: state });
    }

    render() {
        return (
            <>
                <a id={this.props.id} ref={this.props.ref} className="toggle-HeadContent" onClick={() => this.changeAccordionState()} >
                    {this.props.children}
                </a>
            </>
        )
    }

}


export class AccordionBody extends Component<IProps, IState> {
    static defaultProps = { id: "", name: "", onClickHandler: "", isOpened: false, height: 0, ref: null };

    constructor(props: any) {
        super(props);
        //todo: kdyz chci nastartovat s otevrenym tak dat tady nejakou hodnotu
        this.state = { height: this.props.height ?? 0, onClickHandler: null, isActive: false }
    }

    setHeight(height: number) {
        this.setState({ height: height });
    }


    render() {
        return (
            <>
                <main className="toggle-Item-block toggle-Body" style={{ maxHeight: this.state.height }} ref={this.props.ref} id={this.props.id} >
                    <div className="toggle-BodyContent Text">
                        {this.props.children}
                    </div>
                </main>
            </>
        )
    }
}
