import { createSlice } from '@reduxjs/toolkit';
import { boolean } from 'yup';
import { DetailOrderItem } from '../../models/orderModel'


export const slice = createSlice({
    name: 'exDetail',
    initialState: { value: new Array<DetailOrderItem>() },
    reducers: {
        addDetail: (state, action) => {
            var tmpArray = state.value;
            
            if (tmpArray.find(x => x.cause_id === action.payload.cause_id) == undefined) {
                tmpArray.push(action.payload);
            }

            state.value = tmpArray;
        },
        removeDetailByCauseId: (state, action) => {
            state.value = state.value.filter(x => x.cause_id !== action.payload);
        },
        removeDetailByIndex: (state, action) => {
            //todo: splice nejak seje podle dokumentace. upravit!
            if (action.payload !== -1)
                state.value.splice(action.payload, 1);
        },
        clearDetail: (state) => {
            state.value = new Array<DetailOrderItem>();
        }
    },
});

export const { addDetail, removeDetailByCauseId, removeDetailByIndex, clearDetail } = slice.actions;

export const selectDetailList = (state: { exDetail: { value: DetailOrderItem[] }; }) => state.exDetail;

export default slice.reducer;