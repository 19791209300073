import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectDetailList, clearDetail, addDetail } from '../../BL/features/detailSlice';
import { ExekuceResult } from '../../models/exekuceModel';
import { DetailTicketPrice } from '../../models/orderModel';

interface Props {
    refPos: any;
    totalCount: number;
    exekuceItems: ExekuceResult[] | null;
}

export default function PaymentBanner({ refPos, totalCount, exekuceItems }: Props) {
    const urlId = useParams<{ id: string }>();
    const detailListVal = useSelector(selectDetailList);
    const [scroll, setScroll] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.pageYOffset < refPos?.current?.getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientHeight);
        });
    }, []);

    const clickAddAll = () => {
        if (exekuceItems != null) {
            exekuceItems.filter(x => x.is_payed === false).map((item, index) => {
                dispatch(addDetail(item));
            })
        }
    }

    const clickRemoveAll = () => {
        dispatch(clearDetail());
    }

    return (
        <>
            <div className={`Banner Banner--result ${scroll && "is-sticky is-sticky-visible"} ${detailListVal.value.length === 0 && "is-option-none"}`}>
                <div className="Container">
                    <div className="Banner-inner">
                        <div className="exekuce-Banner">
                            <h2 className="exekuce-Banner-title Beta">Koupit detail exekucí</h2>
                            <div className="exekuce-Banner-content">
                                <span>Počet vybraných exekucí: <span data-option-listener-counter="">{detailListVal.value.length} / {totalCount}</span></span>
                                {totalCount > 0 && detailListVal.value.length == totalCount &&
                                    <>
                                        <button className="Link color-textBlue" onClick={clickRemoveAll}>
                                            <span className="">Odebrat všechny</span>
                                        </button>
                                    </>
                                }
                                {totalCount > 0 && detailListVal.value.length < totalCount &&
                                    <>
                                        <button className="Link color-textBlue" onClick={clickAddAll}>
                                            <span className="">Vybrat všechny</span>
                                        </button>
                                    </>
                                }
                            </div>
                            <Link to={detailListVal.value.length > 0 ? "/order/detail/" + urlId.id : "#"} className="Button Button--center" data-component="ModalOpener">
                                <span className="Button-content">Koupit {detailListVal.value.length > 1 ? "vybrané detaily" : "vybraný detail"}
                                    {detailListVal.value.length > 0 &&
                                        <>
                                            <span> - <span data-option-listener-price-total="">{detailListVal.value.length * DetailTicketPrice}</span> Kč</span>
                                        </>
                                    }</span>
                            </Link>
                            <small className="exekuce-Banner-note">Zakoupit detail exekucí lze pouze do 24 hodin po zakoupení přehledu exekucí.</small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


