import { SectionFindType } from '../../models/orderModel';
import MainMobileNavSection from './baseMobileFindControl'

interface Props {
    secType: SectionFindType;
}

export default function NapovedaModal(props: Props) {  

    return (
        <>
            <label className="smwMax:u-hidden">              
                <strong className="color-darkBlue mr-20">Vyhledávání</strong>
            </label>
            <label className="md:u-hidden">               
                <strong className="color-darkBlue mr-20">Vyhledávání podle</strong>
                <MainMobileNavSection secType={props.secType} />
            </label>            
        </>
    );
}
