import { Route } from 'react-router';
import Step from '../../components/order/stepBanner';
import OrderAddOrder from '../../components/order/orderAddOrder';
import OrderPayment from '../../components/order/orderPayment';
import OrderDetail from '../../components/order/orderDetail';
import OrderResult from './OrderResult';

export default function Order() {
    return (
        <>
            <Step />
            <Route strict path="/order/s1/" component={OrderAddOrder} />
            <Route strict path="/order/detail/:id" component={OrderDetail} />
            <Route strict path="/order/s2/:id?" component={OrderPayment} />
            <Route strict path="/order/result/:id" component={OrderResult} />
        </>
    );
}
