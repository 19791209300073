import React, { Component, useState } from 'react'

interface Props {
    link?:string;
    header?:string;
    text: string;
    text2?: string;
}

export default function ModalQuestion(props: Props) {
    const [isOpened, setOpened] = useState(false);

    return (
        <>
            <a className="homepage-Form-pokynyOpener Icon Icon--circleQuestionmark mr-10"
                onClick={(e) => { e.stopPropagation(); setOpened(true); document.documentElement.classList.add('is-scrolling-disabled'); }}>{props.link && props.link}</a>
            <div className={isOpened ? "Modal is-visible is-active" : "Modal"}>
                <div className="Modal-background" data-ref="background"></div>
                <div className="Modal-layer" data-ref="layer">
                    <div className="Modal-inner">
                        <div className="Modal-content" data-ref="content">
                            <div className="Modal-text Text" data-ref="text">
                                <div className="Container">
                                    {props.header && <h3>{props.header}</h3>}
                                    <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: props.text2! }}></p>
                                </div>
                            </div>
                            <button type="button" className="Modal-close"
                                onClick={(e) => { e.stopPropagation(); setOpened(false); document.documentElement.classList.remove('is-scrolling-disabled'); }}>Zavřít</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

