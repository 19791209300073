import React, { Component } from 'react'

export class CebiaServices extends Component {
    render() {
        return (
            <section className="Section Section--appended">
            <div className="Container">
                <div className="Grid gap-20 lg:gap-40 gmb-20 Grid--alignCenter">
                    <div className="Grid-cell smw:w-1/2 md:w-1/3 u-transFadeInUp">
                        <a href="https://cz.cebia.com" target="_blank" className="LinkBanner bg-blue">
                            <article className="LinkBanner-body">
                                <div className="LinkBanner-content">
                                    <h3 className="LinkBanner-title">Online prověření historie vozidla</h3>
                                    <p className="LinkBanner-more" aria-hidden="true">Více informací</p>
                                </div>
                            </article>
                        </a>
                    </div>
                    <div className="Grid-cell smw:w-1/2 md:w-1/3 u-transFadeInUp">
                            <a href="https://www.cebianet.cz/pub/web/cs/Sluzby/Detail/exekuce" target="_blank" className="LinkBanner bg-mediumBlue">
                            <article className="LinkBanner-body">
                                <div className="LinkBanner-content">
                                    <h3 className="LinkBanner-title">Kontrola exekucí pro firmy</h3>
                                    <p className="LinkBanner-more" aria-hidden="true">Více informací</p>
                                </div>
                            </article>
                        </a>
                    </div>
                    <div className="Grid-cell smw:w-1/2 md:w-1/3 u-transFadeInUp">
                        <a href="https://www.cebia.cz/" target="_blank" className="LinkBanner bg-darkBlue">
                            <article className="LinkBanner-body">
                                <div className="LinkBanner-content">
                                    <h3 className="LinkBanner-title">Služby Cebia</h3>
                                    <p className="LinkBanner-more" aria-hidden="true">Zobrazit</p>
                                </div>
                            </article>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        );
    }
 }

export default CebiaServices;
