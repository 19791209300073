import React from 'react';
import { ErrorMessage, Field, useField } from 'formik'
import { MainInputComponent, MainInputType } from './mainInput';
import ModalQuestion from '../_base/modalQuestion';

interface IbaseInput {
    component?: MainInputComponent;
    inputType?: MainInputType;
    name: string;
    label?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    question?: string;
    maxLenght?: number;
    inputFilter?: string;
}

const BaseInput: React.FC<IbaseInput> = ({
    component = MainInputComponent.input,
    inputType = MainInputType.text,
    name,
    label,
    placeholder,
    className,
    disabled,
    question,
    maxLenght,
    inputFilter,
}) => {
    const [field, meta] = useField(name);
    function change(e :any)
    {        
        if (inputFilter?.length??0 > 0)      
            e.value=e.value.replace(new RegExp(inputFilter??"",''),'');
    }
    return (
        <>
            {label && <label className="Label" htmlFor={name}>{label}{question && <ModalQuestion text={question} />}</label>}

            <label className="Input2">
                <Field type={inputType}
                    component={component}
                    maxLenght={maxLenght}
                    disabled={disabled}
                    name={name}
                    className={`${className ? className : "Input"} ${component === MainInputComponent.textarea ? "Input--textarea" : ""} ${meta.touched && meta.error ? "is-invalid" : ""} inputDateWidth`} 
                    placeholder={placeholder}
                    onInput={(e:any) => change(e.target)}
                    max="9999-12-31"/>                    
                {inputType === MainInputType.date &&

                    <div className="Input2-helper" >
                        <span aria-hidden="true" className="Icon Icon--calendar"></span>
                    </div>
                }
            </label>
            <ErrorMessage name={name} component="div" className="InputError" />
        </>
    );
};

export default BaseInput;
