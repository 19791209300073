import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { SectionFindType, SearchOptions } from "../../models/orderModel";

interface Props {
    secType: SectionFindType;
}

export default function MainMobileNavSection(varSecType: Props) {
    const history = useHistory();
    const [selected, setSelected] = useState(SectionFindType[varSecType.secType]);

    function handleChange(selectValue: string, history: any) {
        var url = findUrl(selectValue)?.url;
        if (url !== undefined)
            history.push(url);
    }

    function findUrl(value: string) {
        return SearchOptions.find((element) => {
            return element.value === value;
        })
    }

    function capitalize(value: string) {
       return value.charAt(0).toUpperCase() + value.slice(1);
    }  
    return (
        <>
            <select className="homepage-Form-mobileSelect Select capitalize" value={selected} onChange={e => handleChange(e.target.value, history)}>
                {
                    SearchOptions.map((option,index) => (
                        <option key={index} value={option.value}>{capitalize(option.label)}</option>
                    ))
                }
            </select>
        </>
    )
}