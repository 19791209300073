import React from "react";
import { useSelector } from "react-redux";
import { selectOrderValue } from "../../BL/features/orderSlice";
import BaseInput from "../_base/baseInput";

export default function SubjectIc() {
  const oldVal = useSelector(selectOrderValue);

  return (
    <>
      <div className="Section Section--medium">
        <div className="Container">
          <div className="Form-headline text-left">
            Prověřované IČ: <strong>{oldVal.ic}</strong>
          </div>
        </div>
        <div className="Container text-left">
          <p>
            <strong className="color-red">Upozornění:</strong> Podle IČ lze
            vyhledávat pouze právnické osoby (firmy).{" "}
            <strong>Nikoliv fyzické osoby podnikající (živnostníky).</strong>
          </p>
        </div>
      </div>
      <div className="Container">
        <div className="Separator"></div>
      </div>
    </>
  );
}
