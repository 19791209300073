import React, { Component } from "react";
import { ExekuceTicketPrice } from "../../models/orderModel";

export class Howto extends Component {
  render() {
    return (
      <section
        className="Section Section--appended"
        id="section-jak-to-funguje"
      >
        <div className="Container">
          <h2 className="Section-title Section-title--underline Section-title--center Beta">
            Jak to funguje?
          </h2>

          <div className="Grid gap-40 lg:gap-60 gmb-30">
            <div className="Grid-cell smw:w-1/2 lg:w-1/4">
              <article className="homepage-Step">
                <span className="homepage-Step-subtitle">1. krok</span>
                <h3 className="homepage-Step-title">Vyhledávání</h3>
                <p className="homepage-Step-text Text">
                  Dle způsobu vyhledávání zadejte jméno, příjmení a datum
                  narození, rodné číslo nebo IČ právnické osoby (firmy).
                </p>
              </article>
            </div>
            <div className="Grid-cell smw:w-1/2 lg:w-1/4">
              <article className="homepage-Step">
                <span className="homepage-Step-subtitle">2. krok</span>
                <h3 className="homepage-Step-title">Platba</h3>
                <p className="homepage-Step-text Text">
                  Zaplatit lze přes spolehlivou platební bránu GoPay. Kontrola
                  stojí {ExekuceTicketPrice} Kč vč. DPH.
                </p>
              </article>
            </div>
            <div className="Grid-cell smw:w-1/2 lg:w-1/4">
              <article className="homepage-Step">
                <span className="homepage-Step-subtitle">3. krok</span>
                <h3 className="homepage-Step-title">Přehled exekucí</h3>
                <p className="homepage-Step-text Text">
                  Pokud je kontrolovaný subjekt nalezen v registru exekucí,
                  získáte přehled o vedených exekučních řízeních. V případě
                  negativního výsledku není na subjekt vedena žádná exekuce.
                </p>
              </article>
            </div>
            <div className="Grid-cell smw:w-1/2 lg:w-1/4">
              <article className="homepage-Step">
                <span className="homepage-Step-subtitle">4. krok</span>
                <h3 className="homepage-Step-title">Detail exekuce</h3>
                <p className="homepage-Step-text Text">
                  Zobrazení detailu jednotlivé exekuce kontrolovaného subjektu
                  je zpoplatněno částkou {ExekuceTicketPrice} Kč vč. DPH. Nákup
                  detailu exekuce je možný 24 hodin po nákupu přehledu exekucí.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Howto;
