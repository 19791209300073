export class ExekuceData {
    data: ExekuceResult[] | null = null;
    detailData: DetailResult[] | null = null;
    status: number = 0;
    name: string = "";
    surname: string = "";
    birthDate: Date | null = null;
    ic: string = "";
    rc: string = "";
    created: Date = new Date();
    email:string = "";
    variable: string = "";
    expired: boolean = false;   
}

export class ExekuceResult {
    subject_count: string = "";
    ex_cislo: string = "";
    cause_id: string = "";
    datetime_zalozeni: Date | null = null;
    datetime_upravy: Date | null = null;
    posledni_uprava: string = "";
    subjects: ExekuceSubject[] | null = null;
    birthDate: Date | null = null;
    ic: string = "";
    rc: string = "";
    is_payed: boolean = false; 
    error_status: number = -1; // 0 - Ok, 1-chyba exekuce, 2-chyba platby
}

export class DetailResult {
    subjects: ExekuceSubject[] | null = null;
    ex_cislo: string = "";    
    cause_id: string = "";    
    datetime_zalozeni: Date | null = null;
    datetime_vlozeni: Date | null = null;
    datetime_zapisu: Date | null = null;
    jmeno_kancelare: string = "";
    one_time_price: string = "";
    regular_price: string = "";
    monetary_description: string = "";
    nonmonetary_description: string = "";
    documents: DocumentResult[] | null = null;
    detail_error_status: number = -1;
}

export class DocumentResult {
    subjects : string[] | null = null;
    document_type_id: string = "";
    document_type_description: string = "";
    jmeno_soudu: string = "";
    document_vydano: Date | null = null;
    document_vlozeno: Date | null = null;
    document_modified: Date | null = null;
    text: string = "";
}

export class ExekuceSubject {
    subject_id: string = "";
    typ_osoby: string = "";
    jmeno: string = "";
    datum_narozeni: Date | null = null;
    ic: string = "";
    adresa: string = "";
}