import ReactDOMServer from 'react-dom/server'
import { ExekuceResult } from '../../models/exekuceModel'
import { DetailOrderItem, DetailTicketPrice } from '../../models/orderModel';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetailList, addDetail, removeDetailByCauseId } from '../../BL/features/detailSlice';
import Moment from 'react-moment'

interface Props {
    exekuce: ExekuceResult;
    index: number;
    total: number;
}

export default function ExekuceItems({ exekuce, index, total }: Props) {
    const detailListVal = useSelector(selectDetailList);
    const dispatch = useDispatch();

    function renderPerson(exeP: ExekuceResult) {

        if (exeP.ic != null && exeP.ic.length > 0) {
            return (<>
                <span className="bg-lightBlue color-blue mb-5">IČ: <strong>{exeP.ic}</strong></span><br />
                <div className="mb-5"></div>
                <span className="bg-lightBlue color-blue"><strong> </strong></span>
            </>);
        }

        return (<>
            {exeP.rc != null && exeP.rc.length > 0 && <>
                <span className="bg-lightBlue color-blue mb-5">Rodným číslem: <strong>{exeP.rc}</strong></span><br />
                <div className="mb-5"></div>
                <span className="bg-lightBlue color-blue"><strong> </strong></span>
            </>
            }
            {exeP.birthDate != null && <>
                <span className="bg-lightBlue color-blue mb-5">Datem narození: <strong><Moment format='DD. MM. YYYY'>{exeP.birthDate}</Moment></strong></span><br />
                <div className="mb-5"></div>
                <span className="bg-lightBlue color-blue"><strong> </strong></span>
            </>}
        </>);
    }

    const removeItem = (causeId: string) => () => {
        dispatch(removeDetailByCauseId(exekuce.cause_id));
    }

    const addItem = (exekuce: ExekuceResult) => () => {
        const item = new DetailOrderItem();
        item.cause_id = exekuce.cause_id;
        item.description = GetDescription(exekuce);
        item.ex_cislo = exekuce.ex_cislo;
        dispatch(addDetail(item));
    }

    function GetDescription(item: ExekuceResult) {
        return ReactDOMServer.renderToString(<>{item.posledni_uprava} <Moment format='DD. MM. YYYY'>{item.datetime_upravy ?? ""}</Moment></>);
    }

    return (
        <>
            <div className="exekuce-CardList-item">
                <span className="exekuce-CardList-count">{index}/{total}</span>

                <article className={`exekuce-Card exekuce-Card--paid ${(detailListVal.value.length > 0 && detailListVal.value.find(x => x.cause_id === exekuce.cause_id) != undefined) ? "is-added" : ""}`}>
                    <header className="exekuce-Card-header">
                        <h3 className="exekuce-Card-title">
                            Spisová značka: <strong className="color-red">{exekuce.ex_cislo}</strong>
                        </h3>
                        {(detailListVal.value.length === 0 || detailListVal.value.find(x => x.cause_id === exekuce.cause_id) == undefined) &&
                            <div className="exekuce-Card-controls exekuce-Card-controls--add">
                                <button className="Button" value={exekuce.cause_id} onClick={addItem(exekuce)}>
                                    <span className="Button-content">Koupit detail - {DetailTicketPrice} Kč</span>
                                </button>
                            </div>
                        }
                        {(detailListVal.value.length > 0 && detailListVal.value.find(x => x.cause_id === exekuce.cause_id) != undefined) &&

                            <div className="exekuce-Card-controls exekuce-Card-controls--remove">
                                <span className="exekuce-Card-textWithCheck mr-20">
                                    <strong>Přidáno ({DetailTicketPrice} Kč)</strong>
                                </span>
                                <button value={exekuce.cause_id} style={{ height: 45 }} onClick={removeItem(exekuce.cause_id)}>Zrušit</button>
                            </div>
                        }
                    </header>
                    <main className="exekuce-Card-content">
                        {
                            exekuce.subjects?.map((item, index) => (
                                <div key={index}>
                                    <table className="TableDataPair TableDataPair--mobileWrap">
                                        <tbody>
                                            {index == 0 && <>
                                                <tr>
                                                    <th>Vedeno pod zadaným:</th>
                                                    <td>
                                                        {renderPerson(exekuce)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Popis:</th>
                                                    <td dangerouslySetInnerHTML={{ __html: GetDescription(exekuce) }}></td>
                                                </tr>
                                            </>}
                                            {item.jmeno !== null && item.jmeno.length > 0 && <tr>
                                                <th>Jméno / název:</th>
                                                <td><strong>{item.jmeno}</strong></td>
                                            </tr>}
                                            {item.ic !== null && item.ic.length > 0 && <tr>
                                                <th>Jméno / název:</th>
                                                <td><strong>{item.jmeno}</strong></td>
                                            </tr>}
                                            {item.datum_narozeni !== null &&
                                                <tr>
                                                    <th>Datem narození:</th>
                                                    <td><strong><Moment format='DD. MM. YYYY'>{item.datum_narozeni}</Moment></strong></td>
                                                </tr>}
                                            {item.adresa !== null && item.adresa.length > 0 && <tr>
                                                <th>Adresa:</th>
                                                <td><strong>{item.adresa}</strong></td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                    { index > 0 && <div className="Separator mb-30"></div>}
                                </div>
                            ))
                        }  

                        <div className="Separator"></div>
                        <p className="graySmaller pt-10">Zobrazení všech údajů o exekuci je dle vyhlášky č. 329/2008 Sb zpoplatněno. Pro zobrazení všech údajů stiskněte zelené tlačítko výše.</p>
                    </main>
                </article>
            </div>
        </>
    );
}